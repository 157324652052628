/* eslint-disable @typescript-eslint/dot-notation */
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { pathObjects as ps } from './data';

// function is<T extends object>(v: any, k: string): v is T {
//   return k in v;
// }

// interface A {
//   a: string;
// }
// interface B {
//   b: string;
// }
// interface C {
//   c: string;
// }
// type ObjectType = A | B | C;

// function fun(test: ObjectType) {
//   if (is<A>(test, 'a')) {
//     console.log(test.a);
//   } else if (is<B>(test, 'b')) {
//     console.log(test.b);
//   } else if (is<C>(test, 'c')) {
//     console.log(test.c);
//   }
// }

let mapToROs = (children: PathObject[]): RouteObject[] =>
  children.map((x) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routeobj: any = { ...x };
    if ('children' in routeobj && 'children' in x) {
      routeobj['children'] = mapToROs(x['children'] || []);
    }
    if ('name' in routeobj) {
      delete routeobj['name'];
    }
    return routeobj as RouteObject;
  });

export const createRouter = (pathObjects: PathObject[]) => {
  const routeObjects: RouteObject[] = mapToROs(pathObjects);
  return createBrowserRouter(routeObjects);
};

export { ps as pathObjects };

// const createBreadcrumbObjects = (pathObjects: PathObject[]) => {
//   const bs: BreadcrumbObject[] = pathObjects;
// };

// const bsd: BreadcrumbObject[] = [
//   {
//     path: '/login',
//     name: '登录',
//   },
//   {
//     path: '/',
//     name: '首页',
//   },
//   {
//     path: '/sys',
//     name: '系统设置',
//     parent: {
//       path: '/',
//       name: '首页',
//     },
//   },
//   {
//     path: '/sys/users',
//     name: '用户',
//     parent: {
//       path: '/sys',
//       name: '系统设置',
//       parent: {
//         path: '/',
//         name: '首页',
//       },
//     },
//   },
// ];

const pathJoin = (left: string, right: string) => {
  if (!left) return right;
  if (!right) return left;
  if (left === '/') {
    return left + right;
  }
  return left + '/' + right;
};

let flatten = (
  children: PathObject[],
  bos: BreadcrumbObject[],
  last: BreadcrumbObject | null
): BreadcrumbObject[] => {
  const ss = children.map((x) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pathObject = x as any;
    if (pathObject.index) {
      return [] as BreadcrumbObject[];
    }
    const bo: BreadcrumbObject = {
      name: pathObject.name,
      path: pathJoin(last ? last.path : '', pathObject.path),
    };
    if (last) bo.parent = last;
    if (pathObject.children) {
      return [bo].concat(flatten(pathObject.children, bos, bo));
    }
    return [bo];
  });
  const boss = Array.prototype.concat.apply(bos, ss);
  return boss;
};

const breadcrumbObjects = flatten(ps, [], null);

export { breadcrumbObjects };
