import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import { CreateOrgDto } from '../company.interface';
import { createOrg } from '../in-company.service';

const CreateOrgModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  parentId: number;
  companyId: number;
}> = ({ open, setOpen, refresh, parentId, companyId }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createOrg, { manual: true });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: CreateOrgDto) => {
    try {
      setAlert({ display: false });
      value.parentId = parentId;
      await runAsync(companyId, value);
      message.success('新增成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="新增"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="组织名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="roleIds"
          label="角色"
          rules={[{ required: false }]}
          wrapperCol={{ span: 16 }}
        >
          <Select mode="multiple" placeholder="选择您的角色">
            {data ? (
              data.map((r) => (
                <Option key={r.id} value={r.id}>
                  {r.name}
                </Option>
              ))
            ) : (
              <></>
            )}
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default CreateOrgModal;
