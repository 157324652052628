import { FireOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/plots';
import { useRequest } from 'ahooks';
import { Card, Col, Empty, Row, Space, Typography } from 'antd';
import Goback from 'common/Goback';
import { dayjsfmt3 } from 'common/dayjsfmt';
import { StatisticsResult } from 'devices/val-history/val-history.interface';
import { getStatisticsResult } from 'devices/val-history/val-history.service';
import { ProBreadcrumb, ProSpin } from 'pro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDevice } from '../devices.service';
import TemperatureRanger from './TemperatureRanger';
const { Title } = Typography;
type TempCardType = {
  tag: { tagId: number; timeDimId: number; name: string };
  data: StatisticsResult[];
};

const TempDashboard: React.FC = () => {
  const { deviceId } = useParams();
  if (!deviceId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(deviceId);
  const { loading, data } = useRequest(getDevice, { defaultParams: [id] });
  const [tempCards, setTempCards] = useState<TempCardType[]>();

  useEffect(() => {
    const tags = [
      { tagId: 1, timeDimId: 5, name: '淬火一区' },
      { tagId: 2, timeDimId: 5, name: '淬火二区' },
      { tagId: 3, timeDimId: 5, name: '淬火三区' },
      { tagId: 4, timeDimId: 5, name: '淬火四区' },
      { tagId: 5, timeDimId: 5, name: '淬火五区' },
      { tagId: 6, timeDimId: 5, name: '淬火六区' },
      { tagId: 9, timeDimId: 5, name: '回火一区' },
      { tagId: 10, timeDimId: 5, name: '回火二区' },
      { tagId: 11, timeDimId: 5, name: '回火三区' },
      { tagId: 12, timeDimId: 5, name: '回火四区' },
      { tagId: 13, timeDimId: 5, name: '回火五区' },
      { tagId: 14, timeDimId: 5, name: '回火六区' },
    ];

    const getData = () => {
      Promise.all(
        tags.map((tag) =>
          getStatisticsResult({ tagId: tag.tagId, timeDimId: tag.timeDimId })
        )
      ).then((v) => {
        setTempCards(v.map((tag, i) => ({ tag: tags[i], data: v[i] })));
      });
    };
    getData();

    const interval = setInterval(getData, 1000 * 10);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">
              {data && data.name}温控数据大盘
            </h2>
          </div>
        </div>
      </div>
      {loading ? (
        <ProSpin />
      ) : (
        <>
          {data && tempCards ? (
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <TempCard {...tempCards[0]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[1]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[2]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[3]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[4]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[5]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[6]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[7]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[8]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[9]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[10]} />
              </Col>
              <Col span={6}>
                <TempCard {...tempCards[11]} />
              </Col>
            </Row>
          ) : (
            <Card>
              <Empty />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default TempDashboard;

const TempCard: React.FC<TempCardType> = ({ tag, data }) => {
  const current = data[data.length - 1].value;
  const config = {
    height: 120,
    data,
    xField: 'date',
    yField: 'value',
    xAxis: {
      label: null,
      range: [0, 1],
      tickCount: 3,
    },
    yAxis: {
      label: null,
      grid: null,
    },
    meta: {
      value: { alias: '温度', formatter: (v: string) => v + '°' },
      date: {
        alias: '时间',
        formatter: (v: string) => dayjsfmt3(v),
      },
    },
    animate: false,
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  return (
    <Card>
      <Title level={5}>{tag.name}</Title>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Space style={{ fontSize: 32, fontWeight: 600 }}>
            <FireOutlined />
            <span>{current.toFixed(2)}°</span>
          </Space>
        </Col>
        <Col span={24}>
          <TemperatureRanger current={current} lower={780} upper={880} />
        </Col>
        <Col span={24}>
          <Area {...config} />
        </Col>
      </Row>
    </Card>
  );
};
