import { QuestionCircleOutlined, SwapRightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Badge,
  Card,
  Descriptions,
  Empty,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Tooltip,
  type DescriptionsProps,
  Typography,
} from 'antd';
import Goback from 'common/Goback';
import { dayjsfmt } from 'common/dayjsfmt';
import { LinkToDevice } from 'devices/device/components/LinkToDevice';
import { ProBreadcrumb, ProSpin } from 'pro';
import { RefreshButton } from 'pro/table.pro';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlarmItem, AlarmLog } from '../alarm.interface';
import { getAlarm } from '../alarm.service';
import { ConditionType } from 'alarm/config/alarm-config.interface';
import { Tag as Tag2 } from 'devices/tag/tag.interface';
const { Text } = Typography;

const compare = (condition: ConditionType, value: number) => {
  let b = false;
  switch (condition.key) {
    case '>=':
      b = value >= condition.value;
      break;
    case '>':
      b = value > condition.value;
      break;
    case '=':
      b = value === condition.value;
      break;
    case '<':
      b = value < condition.value;
      break;
    case '<=':
      b = value <= condition.value;
      break;
  }
  return b;
};

const formatValue = (tag: Tag2, value: number) => {
  const con = tag.alarmConfig.conditions.find((c) => compare(c, value));
  if (con) {
    return value + ' (' + con.key + ' ' + con.value + ')';
  }
  return value;
};

const AlarmDetail: React.FC = () => {
  const { alarmId } = useParams();
  if (!alarmId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(alarmId);
  const { data, loading, refresh } = useRequest(getAlarm, {
    defaultParams: [id],
    onSuccess: (v) => {},
  });
  const [now, setNow] = useState<string>(dayjsfmt(new Date()));
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setNow(dayjsfmt(new Date()));
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [now]);
  const items: DescriptionsProps['items'] =
    data &&
    (({
      device,
      status,
      alarmCategories,
      startAt,
      endAt,
      noticeTo,
      maintenanceTo,
    }) => [
      {
        key: '1',
        label: '设备',
        children: (
          // <Link to={`/dm/devices/${device.id.toString()}`}>
          //   {device.name + (device.no ? `(${device.no})` : '')}
          // </Link>
          <LinkToDevice {...device} />
        ),
      },
      {
        key: '2',
        label: '报警状态',
        children:
          (status === '报警中' && <Badge status="error" text="报警中" />) ||
          (status === '自动恢复' && <Badge status="default" text="自动恢复" />),
      },

      {
        key: '3',
        label: '报警类型',
        children:
          alarmCategories &&
          alarmCategories.map((cate) => (
            <Tag color="red" key={cate.id}>
              {cate.name}
            </Tag>
          )),
      },
      {
        key: '4',
        label: '持续时间',
        children: endAt ? (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {dayjsfmt(endAt)}
          </Space>
        ) : (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {now}
          </Space>
        ),
      },
      {
        key: '5',
        label: '通知人员',
        children:
          noticeTo &&
          noticeTo
            .map((u) => u.name)
            .filter((e) => e)
            .join(' '),
      },
      {
        key: '6',
        label: '派发人员',
        children:
          maintenanceTo &&
          maintenanceTo
            .map((u) => u.name)
            .filter((e) => e)
            .join(' '),
      },
    ])(data);

  const columns: TableColumnsType<AlarmItem> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '变量',
      render: (_, { tag }) =>
        (tag?.position?.name && `${tag.position.name} - `) + tag?.name,
    },
    {
      title: '报警状态',
      render: (_, { status }) =>
        (status === '报警中' && <Badge status="error" text="报警中" />) ||
        (status === '自动恢复' && <Badge status="default" text="自动恢复" />),
    },
    {
      title: '报警类型',
      render: (_, { alarmCategory }) => (
        <Tag color="red">{alarmCategory.name}</Tag>
      ),
    },
    {
      title: '持续时间',
      render: (_, { startAt, updateAt, endAt }) =>
        endAt ? (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {dayjsfmt(endAt)}
          </Space>
        ) : updateAt ? (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {dayjsfmt(updateAt)}
          </Space>
        ) : (
          dayjsfmt(startAt)
        ),
    },
    {
      title: '值',
      render: (_, { value, tag }) => (
        <>
          {tag?.alarmConfig?.conditions && (
            <Text type="danger">
              {formatValue(tag, value)}{' '}
              <Tooltip
                title={tag.alarmConfig.conditions.map((c) => (
                  <div key={c.key}>
                    值 {c.key} {c.value}
                  </div>
                ))}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Text>
          )}
        </>
      ),
    },
    {
      title: '通知人员',
      render: (_, { noticeTo }) =>
        noticeTo &&
        noticeTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
    {
      title: '派发人员',
      render: (_, { maintenanceTo }) =>
        maintenanceTo &&
        maintenanceTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
  ];
  const expandedRowRender = (record: AlarmItem) => {
    const columns2: TableColumnsType<AlarmLog> = [
      {
        title: '序号',
        dataIndex: 'id',
        width: 100,
      },
      {
        title: '时间',
        render: (_, { date }) => dayjsfmt(date),
      },
      { title: '值', dataIndex: 'value' },
      { title: '消息', dataIndex: 'msg' },
    ];
    return (
      <Table
        size="small"
        columns={columns2}
        dataSource={record.logs.sort((a, b) => b.id - a.id)}
      />
    );
  };

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">报警详情</h2>
          </div>
          <Space>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Card style={{ marginTop: '24px' }}>
        {loading ? (
          <ProSpin />
        ) : data ? (
          <>
            <Descriptions title="报警记录" items={items} />
            {data.alarmItems && data.alarmItems.length > 0 && (
              <Table
                columns={columns}
                dataSource={data.alarmItems}
                expandable={{
                  expandedRowRender,
                }}
              />
            )}
          </>
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};

export default AlarmDetail;
