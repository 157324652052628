import { useRequest } from 'ahooks';
import { Form, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';

interface PropsType<T, R> {
  open: boolean;
  setOpen: Dispatch<
    SetStateAction<{
      open: boolean;
      id: number;
    }>
  >;
  refresh: () => void;
  update: (id: number, value: T) => Promise<unknown>;
  getOne: (id: number) => Promise<R>;
  normRes: ((v: R) => void) | undefined;
  id: number;
  children: React.ReactNode;
}

function UpdateModal<T, R>({
  open,
  setOpen,
  refresh,
  update,
  getOne,
  normRes,
  id,
  children,
}: React.PropsWithChildren<PropsType<T, R>>) {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(update, { manual: true });
  useRequest(getOne, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue(normRes ? normRes(v) : v);
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen({ open: false, id: -1 });
  };
  const onFinish = async (value: T) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      message.success('新增成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <FormAlert {...alert} />
        {children}
      </Form>
    </Modal>
  );
}
export default UpdateModal;
