import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import { apiPrefix as api } from 'common/url.help';
import { CreateClientDto, MqttClient } from './client.interface';

export const createClient = async (value: CreateClientDto) => {
  const url = api(`/devices/${value.deviceId}/clients`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const getMqttClient = async (deviceId: number, clientId: string) => {
  const url = api(`/devices/${deviceId}/clients/${clientId}`);
  return getData(axios.get<MqttClient>(url));
};

export const deleteClient = async (deviceId: number, clientId: string) => {
  const url = api(`/devices/${deviceId}/clients/${clientId}`);
  return getData(axios.delete<void>(url));
};
