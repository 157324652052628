import { useRequest } from 'ahooks';
import { Button, Form, Input, message } from 'antd';
import { useAuth } from 'auth/AuthProvider';
import LoginFormAlert from 'auth/login/LoginFormAlert';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { captcha } from 'auth/auth.service';

const PhoneLoginForm: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const [btnValue, setBtnValue] = useState<string>('获取验证码');
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { loading, error, run } = useRequest(auth.login2, {
    manual: true,
    onSuccess: (result, params) => {
      const state = location.state as { from: Location };
      const from = state?.from?.pathname ?? '/';
      navigate(from, { replace: true });
      message.success('登录成功');
    },
    onError: () => {},
  });

  const sendCaptcha = async () => {
    // 1. 验证手机号，失败抛异常
    // 2. 禁用按钮，启动动画
    // 3. 发送短信
    // 4. 成功提醒
    // 5. 失败不重置动画
    await form.validateFields(['phone']);

    setDisabled(true);
    let time = 60;
    const f1 = () => {
      if (time === 0) {
        setDisabled(false);
        setBtnValue('获取验证码');
        clearInterval(f2);
      } else {
        setBtnValue('重新获取(' + time + ')');
        time = time - 1;
      }
    };
    f1();
    const f2 = setInterval(f1, 1000);

    try {
      await captcha({ phone: form.getFieldValue('phone') });
      message.success('发送成功');
    } catch {
      message.error('发送验证码失败');
    }
  };

  return (
    <Form
      name="phone_login"
      className="login-form"
      form={form}
      size={'large'}
      onFinish={run}
    >
      <LoginFormAlert error={error} />
      <Form.Item
        name="phone"
        validateTrigger={['onBlur']}
        rules={[
          { required: true, message: ' 请输入手机号!' },
          {
            required: false,
            pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, 'g'),
            message: '请输入正确的手机号！',
            validateTrigger: ['onBlur'],
          },
        ]}
      >
        <Input addonBefore="+86" placeholder="请输入手机号" />
      </Form.Item>
      <div className="phone-code-warp">
        <Form.Item
          className="phone-code-input"
          name="code"
          validateTrigger={['onBlur']}
          rules={[
            { required: true, message: '请输入验证码!' },
            {
              required: false,
              pattern: new RegExp(/^\d{4}$/, 'g'),
              message: '请输入4位验证码!',
              validateTrigger: ['onBlur'],
            },
          ]}
        >
          <Input placeholder="请输入验证码" />
        </Form.Item>
        <Button
          className="phone-code-btn"
          onClick={() => sendCaptcha()}
          disabled={disabled}
        >
          {btnValue}
        </Button>
      </div>

      <Form.Item style={{ margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PhoneLoginForm;
