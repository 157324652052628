import { Form, Input, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ProActionButtons,
  ProBreadcrumb,
  ProCreateButtons,
  ProTableModal,
  useTableModal,
} from 'pro';
import { Link } from 'react-router-dom';
import { Company } from './company.interface';
import {
  createInCompany as create,
  deleteInCompany as del,
  getInCompanies as getAll,
  getInCompany as getOne,
  updateInCompany as update,
} from './in-company.service';

const InCompanyFc: React.FC = () => {
  const { aProps, caProps, tableModalProps } = useTableModal(
    getAll,
    getOne,
    create,
    update,
    del
  );

  const columns: ColumnsType<Company> = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: '名称',
      key: 'name',
      dataIndex: 'name',
      render: (_, { id, name }) => <Link to={id.toString()}>{name}</Link>,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <Link to={id.toString()}>组织管理</Link>
          <ProActionButtons id={id} {...aProps} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">内部公司管理</h2>
          <ProCreateButtons {...caProps} />
        </div>
      </div>
      <ProTableModal columns={columns} {...tableModalProps}>
        <Form.Item name="name" label="名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </ProTableModal>
    </>
  );
};

export default InCompanyFc;
