import { useRequest } from 'ahooks';
import { Checkbox, Form, Input, message, Modal, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UpdateRoleDto } from 'auth/auth.interface';
import { getRole, updateRole } from 'auth/auth.service';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { permissions } from 'config/permissions';
import { Dispatch, SetStateAction, useState } from 'react';

const UpdateRoleModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  id: number;
}> = ({ open, setOpen, refresh, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateRole, { manual: true });

  const [checkListValue, setCheckListValue] = useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = useState(
    () => checkListValue.length > 0
  );
  const [checkAll, setCheckAll] = useState(false);

  useRequest(getRole, {
    defaultParams: [id],
    onSuccess: (value) => {
      form.setFieldsValue(value);
      if (value.permissions) {
        onChange(value.permissions);
      }
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: UpdateRoleDto) => {
    try {
      setAlert({ display: false });
      value.permissions = checkListValue as string[];
      await runAsync(id, value);
      message.success('修改成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  const plainOptions = permissions.map((o) => ({
    label: o.name,
    value: o.code,
  }));

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckListValue(e.target.checked ? plainOptions.map((p) => p.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckListValue(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        name="update-role-form"
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="角色名" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="permissions" label="权限" wrapperCol={{ span: 20 }}>
          <div className="permissions-checkbox">
            <Row>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                className="permissions-checkbox-all"
              >
                全选
              </Checkbox>
            </Row>
            <Row>
              <Checkbox.Group
                options={plainOptions}
                value={checkListValue}
                onChange={onChange}
              />
            </Row>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateRoleModal;
