import { useRequest } from 'ahooks';
import {
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Switch,
} from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import { CreateTag2Dto, DaqType, Scale2Type } from '../tag.interface';
import { createTag } from '../tag.service';
import { sleep } from 'common/sleep';
import { getPositionsSelected } from 'devices/position/position.service';
import { getAlarmConfigsSelected } from 'alarm/config/alarm-config.service';

const CreateTagModal: React.FC<{
  deviceId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}> = ({ open, setOpen, refresh, deviceId }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createTag, { manual: true });
  const { data } = useRequest(getPositionsSelected, {
    defaultParams: [deviceId],
  });
  const { data: alarmConfigs } = useRequest(getAlarmConfigsSelected);

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: CreateTag2Dto) => {
    try {
      setAlert({ display: false });
      console.log(value);

      if (value.type === 'raw') {
        const address = value.address;
        value.memaddress = address;
        value.options = {
          subs: [address],
        };
      } else {
        value.options = {
          subs: [value.memaddress],
        };
      }

      const scale2 = value.scale2;
      const plus = scale2?.plus;
      const mul = scale2?.mul;
      if (scale2?.enabled && (plus !== undefined || mul !== undefined)) {
        const scale = {
          mode: 'linear',
          rawLow: 0,
          rawHigh: 1,
          scaledLow: 0,
          scaledHigh: 1,
        };
        if (plus !== undefined) {
          scale.rawLow = -plus;
          scale.rawHigh = -plus + 1;
        }
        if (mul !== undefined) {
          scale.scaledLow = 0;
          scale.scaledHigh = mul;
        }
        value.scale = scale;
      }
      delete value.scale2;
      value.deviceId = deviceId;

      await runAsync(deviceId, value);
      message.success('新增成功!');
      onCancel();
      await sleep();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  const [typeState, setTypeState] = useState('raw');
  const onFormChange = ({
    type,
    daq,
    scale2,
  }: {
    type: string;
    daq: DaqType;
    scale2: Scale2Type;
  }) => {
    type && setTypeState(type);
    daq?.enabled !== undefined &&
      setDaqState((d) => ({ ...d, enabled: daq.enabled }));
    daq?.changed !== undefined &&
      setDaqState((d) => ({ ...d, changed: daq.changed }));
    daq?.interval !== undefined &&
      setDaqState((d) => ({ ...d, interval: daq.interval }));
    scale2?.enabled !== undefined &&
      setScale2State((d) => ({ ...d, enabled: scale2.enabled }));
    scale2?.plus !== undefined &&
      setScale2State((d) => ({ ...d, plus: scale2.plus }));
    scale2?.mul !== undefined &&
      setScale2State((d) => ({ ...d, mul: scale2.mul }));
  };

  const [daqState, setDaqState] = useState<DaqType>({
    enabled: true,
    changed: true,
    interval: 60,
  });

  const [scale2State, setScale2State] = useState<Scale2Type>({
    enabled: false,
    plus: undefined,
    mul: undefined,
  });

  return (
    <Modal
      title="新增变量"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{
          type: typeState,
          daq: daqState,
          scale2: scale2State,
          readwrite: 'readwrite',
        }}
        onValuesChange={onFormChange}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="变量名" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Payload 格式"
          rules={[{ required: true }]}
        >
          <Radio.Group value={typeState} buttonStyle="solid">
            <Radio.Button value="raw">原始</Radio.Button>
            <Radio.Button value="json">JSON</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="readwrite"
          label="读写类型"
          rules={[{ required: true }]}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="readwrite">读写</Radio.Button>
            <Radio.Button value="readonly">只读</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="address"
          label="地址"
          rules={[{ required: true }]}
          wrapperCol={{ span: 16 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="memaddress"
          label="JSON 属性名"
          rules={[{ required: typeState !== 'raw' }]}
          hidden={typeState === 'raw'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="unit"
          label="计量单位"
          extra="如：秒、米 、千克、开尔文等"
        >
          <Input />
        </Form.Item>
        <Form.Item name={['position', 'id']} label="位置">
          <Select
            placeholder="选择位置"
            allowClear
            options={data && data.map((m) => ({ value: m.id, label: m.name }))}
          />
        </Form.Item>
        <Form.Item name="format" label="保留小数位">
          <InputNumber />
        </Form.Item>

        <Form.Item name={['scale2', 'enabled']} label="线性变换">
          <Switch checked={scale2State.enabled} />
        </Form.Item>
        <Form.Item
          name={['scale2', 'plus']}
          label="加"
          hidden={!scale2State.enabled}
        >
          <InputNumber value={scale2State.plus} />
        </Form.Item>
        <Form.Item
          name={['scale2', 'mul']}
          label="乘以"
          hidden={!scale2State.enabled}
        >
          <InputNumber value={scale2State.mul} />
        </Form.Item>

        <Form.Item
          name={['daq', 'enabled']}
          label="保存入库"
          rules={[{ required: true }]}
        >
          <Switch checked={daqState.enabled} />
        </Form.Item>
        <Form.Item
          name={['daq', 'changed']}
          label="变化时保存数据"
          rules={[{ required: true }]}
          hidden={!daqState.enabled}
        >
          <Switch checked={daqState.changed} />
        </Form.Item>
        <Form.Item
          name={['daq', 'interval']}
          label="保存间隔"
          rules={[{ required: true }]}
          hidden={!daqState.enabled}
          extra="保存数据的间隔，单位秒"
        >
          <InputNumber min={1} max={300} value={daqState.interval} />
        </Form.Item>
        <Form.Item name={['alarmConfig', 'id']} label="关联报警配置">
          <Select
            placeholder="选择报警配置"
            allowClear
            options={
              alarmConfigs &&
              alarmConfigs.map((m) => ({
                value: m.id,
                label: m.name,
              }))
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTagModal;
