import { Space } from 'antd';
import { DeleteButton, UpdateButton } from 'pro/table.pro';
import { FC, ReactNode, SetStateAction } from 'react';

export const ProActionButtons: FC<{
  setUpdateState: (
    value: SetStateAction<{
      open: boolean;
      id: number;
    }>
  ) => void;
  refresh: () => void;
  del(id: number): Promise<void>;
  id: number;
  children?: ReactNode;
}> = ({ setUpdateState, refresh, del, id, children }) => {
  return (
    <Space size="middle">
      {children}
      <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
      <DeleteButton onDel={() => del(id).then(refresh)} />
    </Space>
  );
};
