import { useAntdTable } from 'ahooks';
import type { PaginationProps } from 'antd';
import { Card, Empty, List, Space, Tag } from 'antd';
import { ProBreadcrumb, ProSpin } from 'pro';
import { RefreshButton } from 'pro/table.pro';
import { useState } from 'react';
import GbvideoModal from 'video/gbvideo/gbvideo/components/Video';
import LevideoModal from 'video/levideo/levideo/components/Video';
import YsvideoModal from 'video/ysvideo/ysvideo/components/Video';
import { getAllYunvideo } from '../yunvideo.service';
import SwitchTop from './SwitchTop';
import './index.css';
const { Meta } = Card;

const VideoList: React.FC = () => {
  const { loading, data, tableProps, refresh, run } = useAntdTable(
    getAllYunvideo,
    {
      defaultParams: [{ current: 1, pageSize: 8 }],
      defaultPageSize: 8,
    }
  );
  const gbvideoState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const ysvideoState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const levideoState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const onChange: PaginationProps['onChange'] = (page) => {
    run({ current: page, pageSize: 8 });
  };

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">视频监控</h2>
          <RefreshButton onRefresh={refresh} />
        </div>
      </div>
      {loading ? (
        <ProSpin />
      ) : (
        <>
          {data && data.list.length > 0 ? (
            <div>
              <List
                pagination={{
                  ...tableProps.pagination,
                  showTotal: (t) => `共 ${t} 条`,
                  onChange,
                }}
                grid={{
                  gutter: 16,
                  column: 4,
                }}
                dataSource={data.list}
                renderItem={({
                  name,
                  videoYunType,
                  deviceId,
                  channelId,
                  channelCode,
                  top,
                  id,
                }) => (
                  <List.Item>
                    {videoYunType === '萤石云' ? (
                      <Card
                        hoverable
                        style={{ width: 300 }}
                        cover={
                          <img
                            className="video-window"
                            alt="img"
                            src={`/files/screenshot/ys-${deviceId}-${channelId}.jpeg`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/default_img.png';
                            }}
                            onClick={() => ysvideoState[1]({ open: true, id })}
                            style={{ width: '300px', height: '168px' }}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="device-card-title">
                              <Space>
                                {name}
                                <Tag color="processing">{videoYunType}</Tag>
                              </Space>
                              <Space>
                                <SwitchTop id={id} top={top} />
                              </Space>
                            </div>
                          }
                          description={
                            <>
                              <div>Device Id: {deviceId}</div>
                              <div>Channel Id: {channelId}</div>
                            </>
                          }
                        />
                      </Card>
                    ) : videoYunType === '乐橙云' ? (
                      <Card
                        hoverable
                        style={{ width: 300 }}
                        cover={
                          <img
                            className="video-window"
                            alt="img"
                            src={`//v.cz-calculus.com:18080/api/device/query/snap/${deviceId}/${channelId}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/default_img.png';
                            }}
                            onClick={() => levideoState[1]({ open: true, id })}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="device-card-title">
                              <Space>
                                {name}
                                <Tag color="processing">{videoYunType}</Tag>
                              </Space>
                              <Space>
                                <SwitchTop id={id} top={top} />
                              </Space>
                            </div>
                          }
                          description={
                            <>
                              <div>Device Id: {deviceId}</div>
                              <div>Channel Id: {channelId}</div>
                            </>
                          }
                        />
                      </Card>
                    ) : (
                      <Card
                        hoverable
                        style={{ width: 300 }}
                        cover={
                          <img
                            className="video-window"
                            alt="img"
                            src={`//v.cz-calculus.com:18080/api/device/query/snap/${deviceId}/${channelCode}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/default_img.png';
                            }}
                            onClick={() => gbvideoState[1]({ open: true, id })}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div className="device-card-title">
                              <Space>
                                {name}
                                <Tag color="processing">{videoYunType}</Tag>
                              </Space>
                              <Space>
                                <SwitchTop id={id} top={top} />
                              </Space>
                            </div>
                          }
                          description={
                            <>
                              <div>Device Id: {deviceId}</div>
                              <div>Channel Code: {channelCode}</div>
                            </>
                          }
                        />
                      </Card>
                    )}
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <Card>
              <Empty />
            </Card>
          )}
        </>
      )}
      {gbvideoState[0].open && <GbvideoModal state={gbvideoState} />}
      {ysvideoState[0].open && <YsvideoModal state={ysvideoState} />}
      {levideoState[0].open && <LevideoModal state={levideoState} />}
    </>
  );
};

export default VideoList;
