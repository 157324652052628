import { useRequest } from 'ahooks';
import {
  Card,
  Descriptions,
  Empty,
  Image,
  Tag,
  type DescriptionsProps,
} from 'antd';
import Goback from 'common/Goback';
import { ProBreadcrumb, ProSpin } from 'pro';
import { useParams } from 'react-router-dom';
import { getSolution } from '../solution.service';

const SolutionDetail: React.FC = () => {
  const { solutionId } = useParams();
  if (!solutionId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(solutionId);
  const { data, loading } = useRequest(getSolution, {
    defaultParams: [id],
    onSuccess: (v) => {},
  });
  const m1 =
    data?.problemAttachments &&
    data?.problemAttachments.map((att) => att.downloadUrl as string);
  const m2 =
    data?.noteAttachments &&
    data?.noteAttachments.map((att) => att.downloadUrl as string);
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: '选择故障类型',
      children:
        data?.categories &&
        data.categories.map((cate) => (
          <Tag color="blue" key={cate.id}>
            {cate.name}
          </Tag>
        )),
      span: 3,
    },
    {
      key: '2',
      label: '问题描述',
      children: data?.problem,
      span: 3,
    },
    {
      key: '3',
      label: '附件',
      children: m1 ? (
        <Image.PreviewGroup items={m1}>
          {m1.map((m) => (
            <Image width={100} key={m} src={m} />
          ))}
        </Image.PreviewGroup>
      ) : (
        '-'
      ),
      span: 3,
    },
    {
      key: '4',
      label: '方案步骤',
      children: data?.note,
      span: 3,
    },
    {
      key: '5',
      label: '附件',
      children: m2 ? (
        <Image.PreviewGroup items={m2}>
          {m2.map((m) => (
            <Image width={100} key={m} src={m} />
          ))}
        </Image.PreviewGroup>
      ) : (
        '-'
      ),
      span: 3,
    },
  ];

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">解决方案</h2>
          </div>
        </div>
      </div>
      <Card style={{ maxWidth: '880px', marginTop: '24px' }}>
        {loading ? (
          <ProSpin />
        ) : data ? (
          <Descriptions title={data.name} items={items} />
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};

export default SolutionDetail;
