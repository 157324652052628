import { useAntdTable, useRequest } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Position } from '../position.interface';
import { deletePosition, getPositions } from '../position.service';
import CreatePositionModal from './Create';
import UpdatePositionModal from './Update';
import Goback from 'common/Goback';
import { getDevice } from 'devices/device/devices.service';

const PositionList: React.FC = () => {
  const { deviceId } = useParams();
  if (!deviceId) {
    throw new Error('系统错误!');
  }
  const deviceid = Number.parseInt(deviceId);
  const { tableProps, refresh } = useAntdTable(getPositions, {
    defaultParams: [{ deviceId: deviceid, current: 1, pageSize: 10 }],
    //refreshOnWindowFocus: true,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [positionId, setPositionId] = useState(0);
  const { data } = useRequest(getDevice, {
    defaultParams: [deviceid],
  });
  const columns: ColumnsType<Position> = [
    {
      title: '序号',
      dataIndex: 'id',
    },
    {
      title: '位置',
      dataIndex: 'name',
    },
    {
      title: '排序',
      dataIndex: 'order',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton
            onUpdate={() => {
              setPositionId(id);
              setUpdateState(true);
            }}
          />
          <DeleteButton
            onDel={() => deletePosition(deviceid, id).then(refresh)}
          />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">
              {data && '设备' + data.name + '的'}位置管理
            </h2>
          </div>
        </div>
      </div>
      <div className="content-toolbar">
        <div className="content-toolbar-container">
          <h3 className="content-toolbar-title">位置清单</h3>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {createState && (
        <CreatePositionModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
          deviceId={deviceid}
        />
      )}
      {updateState && (
        <UpdatePositionModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={positionId}
          deviceId={deviceid}
        />
      )}
    </>
  );
};

export default PositionList;
