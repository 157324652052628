import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import ProModal from 'pro/proModal/ProModal';

interface PropsType<T extends object, CDto, UDto> {
  tbProps: {
    rowKey: string;
    pagination: false | TablePaginationConfig | undefined;
    dataSource: T[];
    loading: boolean;
    onChange: (
      pagination: unknown,
      filters?: unknown,
      sorter?: unknown
    ) => void;
  };
  cuModalProps: {
    createState: boolean;
    updateState: {
      open: boolean;
      id: number;
    };
    setCreateState: React.Dispatch<React.SetStateAction<boolean>>;
    setUpdateState: React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        id: number;
      }>
    >;
    refresh: () => void;
    create: (value: CDto) => Promise<unknown>;
    update: (id: number, value: UDto) => Promise<unknown>;
    getOne: (id: number) => Promise<T>;
  };
  children: React.ReactNode;
  columns?: ColumnsType<T> | undefined;
}

export function ProTableModal<T extends object, CDto, UDto>({
  tbProps: tableProps,
  cuModalProps: creUpModalProps,
  columns,
  children,
}: React.PropsWithChildren<PropsType<T, CDto, UDto>>) {
  return (
    <>
      <Table columns={columns} {...tableProps} />
      <ProModal<CDto, UDto, T> {...creUpModalProps}>{children}</ProModal>
    </>
  );
}
