import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadFile, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';
import { getAuthorization } from 'auth/AuthProvider';
import { notEmpty } from 'common/filter.helper';
import { useState } from 'react';
import { FileMetadataDto } from './files.interface';
import { FILE_UPLOAD_PATH, deleteFile } from './files.service';

export const UploadInput: React.FC<{
  onChange?: (e?: FileMetadataDto[]) => void;
  fileState?: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UploadFile<any>[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
  ];
}> = ({ onChange, fileState }) => {
  const [fileList, setFileList] = useState<UploadFile[]>();

  const getFileList = () => (fileState ? fileState[0] : fileList);
  const getSetFileList = () => (fileState ? fileState[1] : setFileList);

  const props: UploadProps = {
    name: 'file',
    action: FILE_UPLOAD_PATH,
    headers: {
      Authorization: getAuthorization(),
    },
    onChange(info: UploadChangeParam<UploadFile<FileMetadataDto>>) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.downloadUrl;
        }
        return file;
      });

      getSetFileList()(newFileList);
      const metas = newFileList.map((f) => f.response).filter(notEmpty);
      onChange && onChange(metas);
    },
    onRemove(file) {
      deleteFile(file.response.deleteUrl);
    },
  };

  return (
    <Upload {...props} fileList={getFileList()}>
      <Button icon={<UploadOutlined />}>上传</Button>
    </Upload>
  );
};
