import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import { sleep } from 'common/sleep';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import { SetValueDto } from '../tag.interface';
import { setVal } from '../tag.service';

const SetValModal: React.FC<{
  deviceId: number;
  id: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}> = ({ open, setOpen, refresh, deviceId, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(setVal, { manual: true });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: SetValueDto) => {
    try {
      setAlert({ display: false });

      await runAsync(deviceId, id, value);
      message.success('设置成功!');
      onCancel();
      await sleep();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="设置值"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="value" label="值" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SetValModal;
