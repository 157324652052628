export interface StatisticsResult {
  date: Date;
  value: number;
}
export type TimeDimensionType = {
  id: number;
  timeRanges: string;
  hourCount?: number;
  minCount?: number;
  window?: number;
  expectedCount?: number;
};
export const timeDimensions: TimeDimensionType[] = [
  {
    id: 1,
    timeRanges: '最近1小时',
    hourCount: 1,
    minCount: 60,
    window: 1,
    expectedCount: 60,
  },
  {
    id: 2,
    timeRanges: '最近3小时',
    hourCount: 3,
    minCount: 180,
    window: 1,
    expectedCount: 180,
  },
  {
    id: 3,
    timeRanges: '最近6小时',
    hourCount: 6,
    minCount: 360,
    window: 5,
    expectedCount: 72,
  },
  {
    id: 4,
    timeRanges: '最近12小时',
    hourCount: 12,
    minCount: 720,
    window: 10,
    expectedCount: 72,
  },
  {
    id: 5,
    timeRanges: '最近1天',
    hourCount: 24,
    minCount: 1440,
    window: 10,
    expectedCount: 144,
  },
  {
    id: 6,
    timeRanges: '最近3天',
    hourCount: 72,
    minCount: 4320,
    window: 30,
    expectedCount: 144,
  },
  {
    id: 7,
    timeRanges: '最近7天',
    hourCount: 168,
    minCount: 10080,
    window: 60,
    expectedCount: 168,
  },
  {
    id: 8,
    timeRanges: '最近15天',
    hourCount: 360,
    minCount: 21600,
    window: 120,
    expectedCount: 180,
  },
  {
    id: 9,
    timeRanges: '自定义',
  },
];
