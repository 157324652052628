import { useAntdTable, useRequest } from 'ahooks';
import { Alert, Space, Table, Tag as Tag2 } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Goback from 'common/Goback';
import { dayjsfmt } from 'common/dayjsfmt';
import { sleep } from 'common/sleep';
import { getDevice } from 'devices/device/devices.service';
import { ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tag } from '../tag.interface';
import { deleteTag, getTags } from '../tag.service';
import CopyTagModal from './Copy';
import CreateTagModal from './Create';
import SetValModal from './SetVal';
import UpdateTagModal from './Update';

const TagList: React.FC = () => {
  const devid = useParams().deviceId;
  if (!devid) {
    throw new Error('系统错误!');
  }
  const deviceId = Number(devid);
  const { data } = useRequest(getDevice, {
    defaultParams: [deviceId],
  });

  const { tableProps, refresh } = useAntdTable(getTags, {
    defaultParams: [{ deviceId: deviceId, current: 1, pageSize: 10 }],
    refreshOnWindowFocus: true,
    pollingInterval: 1000 * 10,
  });

  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [copyState, setCopyState] = useState(false);
  const [setValState, setSetValState] = useState(false);
  const [tagId, setTagId] = useState(0);
  const valfmt = (value: string | undefined, unit: string | undefined) => {
    if (value) {
      return value + (unit ? ' ' + unit : '');
    }
  };
  const columns: ColumnsType<Tag> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '位置',
      render: (_, { position }) => position?.name,
    },
    {
      title: '变量名称',
      dataIndex: 'name',
    },
    {
      title: '地址',
      dataIndex: 'address',
      render: (_, { address, type, memaddress }) =>
        type === 'raw' ? address : address + '[' + memaddress + ']',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (_, { type, readwrite }) => (
        <>
          {type === 'raw' ? (
            <Tag2 color="blue">原始</Tag2>
          ) : (
            <Tag2 color="blue">JSON</Tag2>
          )}
          {readwrite === 'readwrite' ? (
            <Tag2 color="blue">读写</Tag2>
          ) : (
            <Tag2 color="blue">只读</Tag2>
          )}
        </>
      ),
    },
    {
      title: '值',
      dataIndex: 'value',
      render: (_, { value, unit }) => valfmt(value, unit),
    },
    {
      title: '时间',
      dataIndex: 'timestamp',
      render: (_, { timestamp }) => dayjsfmt(timestamp),
    },
    {
      title: '启用规则',
      render: (_, { daq, scale, alarmConfig }) => (
        <>
          {scale && <Tag2 color="blue">线性变换</Tag2>}
          {daq?.enabled && <Tag2 color="blue">保存入库</Tag2>}
          {alarmConfig?.enabled && <Tag2 color="blue">启用报警</Tag2>}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id, readwrite }) => (
        <Space size="middle">
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              setTagId(id);
              setCopyState(true);
            }}
          >
            复制创建
          </a>
          <UpdateButton
            onUpdate={() => {
              setTagId(id);
              setUpdateState(true);
            }}
          />
          <DeleteButton
            onDel={() =>
              deleteTag(deviceId, id).then(() => {
                sleep(1000);
                refresh();
              })
            }
          />
          {readwrite === 'readwrite' && (
            <a
              href="/"
              onClick={async (e) => {
                e.preventDefault();
                setTagId(id);
                setSetValState(true);
              }}
            >
              写入数据
            </a>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />

        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">
              {data && '设备' + data.name + '的'}变量管理
            </h2>
          </div>
        </div>
      </div>
      <div className="content-toolbar">
        <Alert
          message='地址请按照 "/" + "项目名称字母" + "/" + "网关的 ClientId" + "/" + "自定义部分" 进行录入，避免影响变量的导入导出功能。'
          type="info"
          style={{ margin: '16px 0' }}
        />
        <div className="content-toolbar-container">
          <h3 className="content-toolbar-title">变量清单</h3>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>

      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {createState && (
        <CreateTagModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
          deviceId={deviceId}
        />
      )}
      {updateState && (
        <UpdateTagModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={tagId}
          deviceId={deviceId}
        />
      )}
      {copyState && (
        <CopyTagModal
          open={copyState}
          setOpen={setCopyState}
          refresh={refresh}
          copyId={tagId}
          deviceId={deviceId}
        />
      )}
      {setValState && (
        <SetValModal
          open={setValState}
          setOpen={setSetValState}
          refresh={refresh}
          id={tagId}
          deviceId={deviceId}
        />
      )}
    </>
  );
};

export default TagList;
