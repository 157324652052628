import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Alert, Button, Form, Input, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProModal } from 'pro';
import {
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import {
  CreateLeconfigDto,
  Leconfig,
  UpdateLeconfigDto,
} from '../leconfig.interface';
import {
  createLeconfig,
  deleteLeconfig,
  getLeconfig,
  getLeconfigs,
  updateLeconfig,
} from '../leconfig.service';

const LeconfigList: React.FC = () => {
  const { tableProps, refresh, data } = useAntdTable(getLeconfigs, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );

  const columns: ColumnsType<Leconfig> = [
    {
      title: 'AppId',
      dataIndex: 'appid',
    },
    {
      title: 'AppSecret',
      dataIndex: 'appSecret',
    },
    {
      title: 'AccessToken',
      dataIndex: 'accessToken',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
          <DeleteButton onDel={() => deleteLeconfig(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <Alert
          message={
            <>
              初始化参数可从乐橙云官网获取：
              <a
                href="https://open.imou.com/consoleNew/myApp/appInfo"
                target="_blank"
                rel="noreferrer"
              >
                获取 AppId AppSecret
              </a>
            </>
          }
          type="info"
          style={{ marginTop: '16px' }}
        />
        <div className="content-toolbar-container">
          <div className="content-toolbar-title"></div>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateState(true)}
              disabled={data && data.total > 0}
            >
              新增
            </Button>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <ProModal<CreateLeconfigDto, UpdateLeconfigDto, Leconfig>
        createState={createState}
        updateState={updateState}
        setCreateState={setCreateState}
        setUpdateState={setUpdateState}
        create={createLeconfig}
        update={updateLeconfig}
        getOne={getLeconfig}
        refresh={refresh}
      >
        <Form.Item name="appid" label="AppId" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="appSecret"
          label="AppSecret"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="accessToken" label="AccessToken">
          <Input />
        </Form.Item>
      </ProModal>
    </>
  );
};

export default LeconfigList;
