import { AlarmCategoryList } from 'alarm';
import AlarmContainer from 'alarm/alarm/components/Container';
import AlarmDetail from 'alarm/alarm/components/Detail';
import MessageLogList from 'alarm/msglog/components/List';
import AlarmConfigCreate from 'alarm/config/components/Create';
import AlarmConfigDetail from 'alarm/config/components/Detail';
import AlarmConfigList from 'alarm/config/components/List';
import AlarmConfigUpdate from 'alarm/config/components/Update';
import {
  CompanyDetailFc,
  CompanyFc,
  InCompanyDetailFc,
  InCompanyFc,
  Login,
  RoleFc,
  UserFc,
} from 'auth';
import { DeviceCreate, DeviceDetail, DeviceList, DeviceUpdate } from 'devices';
import { ClientDetail } from 'devices/client/components/Detail';
import OperlogList from 'devices/log/components/List';
import PositionList from 'devices/position/components/List';
import TagList from 'devices/tag/components/List';
import ValHistoryList from 'devices/val-history/components/List';
import LayoutFc from 'layout';
import { Navigate } from 'react-router-dom';
import GbvideoList from 'video/gbvideo/gbvideo/components/List';
import LevideoContainer from 'video/levideo/components/Container';
import VideoList from 'video/video/components/List';
import YsvideoContainer from 'video/ysvideo/components/Container';
import FailureCategoryList from 'workorder/failure-category/components/List';
import SolutionCreate from 'workorder/solution/components/Create';
import SolutionDetail from 'workorder/solution/components/Detail';
import SolutionList from 'workorder/solution/components/List';
import SolutionUpdate from 'workorder/solution/components/Update';
import WorkorderCategoryList from 'workorder/workorder-category/components/List';
import WorkorderCreate from 'workorder/workorder/components/Create';
import WorkorderDetail from 'workorder/workorder/components/Detail';
import WorkorderList from 'workorder/workorder/components/List';
import WorkorderUpdate from 'workorder/workorder/components/Update';
import TempDashboard from 'devices/device/components/TempDashboard';

/*
  path 用来：路由、显示页面、导航菜单、导航状态、面包屑、标题、跳转、api、鉴权
  react-route 相关：路由、显示页面、导航菜单、导航状态、面包屑、标题、跳转
*/
export const pathObjects: PathObject[] = [
  { path: '/login', name: '登录', element: <Login /> },
  {
    path: '/',
    name: '首页',
    element: <LayoutFc />,
    children: [
      { index: true, element: <Navigate to="dm" replace /> },
      // { path: 'dashboard', name: '概述', element: <Dashboard /> },
      {
        path: 'sys',
        name: '系统设置',
        children: [
          { index: true, element: <Navigate to="companies" replace /> },
          { path: 'users', name: '用户', element: <UserFc /> },
          { path: 'roles', name: '角色', element: <RoleFc /> },
          {
            path: 'in-companies',
            name: '内部公司',
            children: [
              { index: true, element: <InCompanyFc /> },
              {
                path: ':companyId',
                name: '明细配置',
                element: <InCompanyDetailFc />,
              },
            ],
          },
          {
            path: 'companies',
            name: '公司',
            children: [
              { index: true, element: <CompanyFc /> },
              {
                path: ':companyId',
                name: '明细配置',
                element: <CompanyDetailFc />,
              },
            ],
          },
        ],
      },
      {
        path: 'al',
        name: '报警管理',
        children: [
          { index: true, element: <Navigate to="alarms" replace /> },
          {
            path: 'alarms',
            name: '报警',
            children: [
              { index: true, element: <AlarmContainer /> },
              {
                path: ':alarmId',
                name: '报警详情',
                element: <AlarmDetail />,
              },
            ],
          },
          {
            path: 'alarm-logs',
            name: '报警通知',
            children: [{ index: true, element: <MessageLogList /> }],
          },
          {
            path: 'alarm-configs',
            name: '报警配置',
            children: [
              { index: true, element: <AlarmConfigList /> },
              {
                path: 'new',
                name: '添加配置',
                element: <AlarmConfigCreate />,
              },
              {
                path: ':configId',
                name: '配置详情',
                element: <AlarmConfigDetail />,
              },
              {
                path: ':configId/edit',
                name: '编辑配置',
                element: <AlarmConfigUpdate />,
              },
            ],
          },
          {
            path: 'alarm-categories',
            name: '报警类型',
            children: [{ index: true, element: <AlarmCategoryList /> }],
          },
        ],
      },
      {
        path: 'wo',
        name: '工单管理',
        children: [
          { index: true, element: <Navigate to="workorder" replace /> },
          {
            path: 'workorder',
            name: '工单',
            children: [
              { index: true, element: <WorkorderList /> },
              {
                path: 'new',
                name: '添加工单',
                element: <WorkorderCreate />,
              },
              {
                path: ':workorderId',
                name: '工单详情',
                element: <WorkorderDetail />,
              },
              {
                path: ':workorderId/edit',
                name: '编辑工单',
                element: <WorkorderUpdate />,
              },
            ],
          },
          {
            path: 'workorder-categories',
            name: '工单类型',
            children: [{ index: true, element: <WorkorderCategoryList /> }],
          },
          {
            path: 'solutions',
            name: '解决方案',
            children: [
              { index: true, element: <SolutionList /> },
              { path: 'new', name: '添加方案', element: <SolutionCreate /> },
              {
                path: ':solutionId',
                name: '方案详情',
                element: <SolutionDetail />,
              },
              {
                path: ':solutionId/edit',
                name: '编辑方案',
                element: <SolutionUpdate />,
              },
            ],
          },
          {
            path: 'failure-categories',
            name: '故障类型',
            children: [{ index: true, element: <FailureCategoryList /> }],
          },
        ],
      },
      {
        path: 'dm',
        name: '设备管理',
        children: [
          { index: true, element: <Navigate to="devices" replace /> },
          {
            path: 'devices',
            name: '设备',
            children: [
              { index: true, element: <DeviceList /> },
              { path: 'new', name: '添加设备', element: <DeviceCreate /> },
              {
                path: ':deviceId',
                name: '设备明细',
                element: <DeviceDetail />,
              },
              {
                path: ':deviceId/edit',
                name: '编辑设备',
                element: <DeviceUpdate />,
              },
              {
                path: ':deviceId/clients/:clientId',
                name: '网关详情',
                element: <ClientDetail />,
              },
              {
                path: ':deviceId/tags',
                name: '设置变量',
                element: <TagList />,
              },
              {
                path: ':deviceId/positions',
                name: '设置位置',
                element: <PositionList />,
              },
              {
                path: ':deviceId/temp-dashboard',
                name: '设置位置',
                element: <TempDashboard />,
              },
            ],
          },
          {
            path: 'histories',
            name: '历史数据',
            element: <ValHistoryList />,
          },
          {
            path: 'logs',
            name: '写入数据记录',
            element: <OperlogList />,
          },

          // {
          //   path: ':deviceId',
          //   name: '设备明细',
          //   children: [
          //     { index: true, element: <DetailDevice /> },
          //     { path: 'edit', name: '编辑设备', element: <UpdateDevice /> },

          //     {
          //       path: 'clients/:clientId',
          //       name: '网关详情',
          //       element: <ClientDetail />,
          //     },

          //     {
          //       path: 'tags',
          //       name: '设置变量',
          //       element: <TagFc />,
          //     },
          //   ],
          // },
        ],
      },
      {
        path: 'vd',
        name: '云视频',
        children: [
          { index: true, element: <Navigate to="video" replace /> },
          {
            path: 'video',
            name: '视频监控',
            children: [{ index: true, element: <VideoList /> }],
          },
          {
            path: 'gbvideo',
            name: 'GB28181配置',
            children: [{ index: true, element: <GbvideoList /> }],
          },
          {
            path: 'ysvideo',
            name: '萤石云配置',
            children: [{ index: true, element: <YsvideoContainer /> }],
          },
          {
            path: 'levideo',
            name: '乐橙云配置',
            children: [{ index: true, element: <LevideoContainer /> }],
          },
        ],
      },
    ],
  },
];
