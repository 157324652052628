import { Alert } from 'antd';
import { getErrorMessage } from 'pro/FormAlert';

const LoginFormAlert: React.FC<{ error?: Error }> = ({ error }) => {
  return (
    <>
      {error && (
        <Alert
          className="form-alert"
          message={getErrorMessage(error)}
          type="error"
          showIcon
          style={{ margin: '0 0 24px 0' }}
        />
      )}
    </>
  );
};

export default LoginFormAlert;
