import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import { Alarm } from './alarm.interface';

export const getAlarms = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarms?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Alarm>>(url));
  return { total: data.total, list: data.items };
};
export const getAlarms2 = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarms/today?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Alarm>>(url));
  return { total: data.total, list: data.items };
};
export const getAlarms3 = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarms/history?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Alarm>>(url));
  return { total: data.total, list: data.items };
};

export const getAlarm = async (id: number) => {
  const url = api(`/alarms/${id}`);
  return getData(axios.get<Alarm>(url));
};
export const deleteAlarm = async (id: number) => {
  const url = api(`/alarms/${id}`);
  return getData(axios.delete<void>(url));
};
