import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Ysconfig,
  CreateYsconfigDto,
  UpdateYsconfigDto,
} from './ysconfig.interface';
import { apiPrefix as api } from 'common/url.help';

export const getYsconfigs = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/ysconfigs?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Ysconfig>>(url));
  return { total: data.total, list: data.items };
};

export const getYsconfig = async (id: number) => {
  const url = api(`/video/ysconfigs/${id}`);
  return getData(axios.get<Ysconfig>(url));
};

export const deleteYsconfig = async (id: number) => {
  const url = api(`/video/ysconfigs/${id}`);
  return getData(axios.delete<void>(url));
};

export const createYsconfig = async (value: CreateYsconfigDto) => {
  const url = api(`/video/ysconfigs`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateYsconfig = async (id: number, value: UpdateYsconfigDto) => {
  const url = api(`/video/ysconfigs/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const getYsConfig = async () => {
  const res = await Promise.all([getAccessToken(), getTemplateId()]);
  return res;
};

// 错误时直接从 ysyun 取数据
export const refreshAccessToken = async () => {
  const url = api('/video/ysconfigs/refreshtoken');
  const t = await getData(
    axios.get<{ accessToken: string; expireTime: number }>(url)
  );
  if (!t) {
    throw new Error('萤石云配置错误！');
  }
  localStorage.setItem('ys-accessToken', t.accessToken);
  localStorage.setItem('ys-accessToken-expireTime', t.expireTime.toString());
  return t.accessToken;
};

// 通常按照 localStorage postgres ysyun 三个缓存级别取数据
const getAccessToken = async () => {
  const at = localStorage.getItem('ys-accessToken');
  const et = Number(localStorage.getItem('ys-accessToken-expireTime'));
  if (at && et && et > Date.now()) {
    return at;
  }

  const url = api('/video/ysconfigs/token');
  const t = await getData(
    axios.get<{ accessToken: string; expireTime: number }>(url)
  );
  if (!t) {
    throw new Error('萤石云配置错误！');
  }
  localStorage.setItem('ys-accessToken', t.accessToken);
  localStorage.setItem('ys-accessToken-expireTime', t.expireTime.toString());
  return t.accessToken;
};

const getTemplateId = async () => {
  const tid = localStorage.getItem('ys-templateId');
  if (tid) {
    return tid;
  }

  const url = api('/video/ysconfigs/template');
  const t = await getData(axios.get<string>(url));
  localStorage.setItem('ys-templateId', t || 'simple');
  return t;
};
