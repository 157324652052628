import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  CreateWorkorderDto,
  UpdateWorkorderDto,
  UpdateWorkorderLikeDto,
  UpdateWorkorderMaintainerDto,
  Workorder,
} from './workorder.interface';

export const getWorkorders = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/workorder?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Workorder>>(url));
  return { total: data.total, list: data.items };
};

export const getWorkorder = async (id: number) => {
  const url = api(`/workorder/${id}`);
  return getData(axios.get<Workorder>(url));
};

export const deleteWorkorder = async (id: number) => {
  const url = api(`/workorder/${id}`);
  return getData(axios.delete<void>(url));
};

export const createWorkorderByLeader = async (value: CreateWorkorderDto) => {
  const url = api(`/workorder/leader`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const createWorkorderByClient = async (value: CreateWorkorderDto) => {
  const url = api(`/workorder/client`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateWorkorderByLeader = async (
  id: number,
  value: UpdateWorkorderDto
) => {
  const url = api(`/workorder/leader/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
export const updateWorkorderByMaintainer = async (
  id: number,
  value: UpdateWorkorderMaintainerDto
) => {
  const url = api(`/workorder/maintainer/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const closeWorkorder = async (id: number) => {
  const url = api(`/workorder/close/${id}`);
  return getData(axios.patch(url, null, { handlerErrorManually: true }));
};

export const likeWorkorder = async (
  id: number,
  value: UpdateWorkorderLikeDto
) => {
  const url = api(`/workorder/like/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
