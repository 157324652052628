import { useRequest } from 'ahooks';
import { DatePicker, Form, Input, message, Modal, UploadFile } from 'antd';
import {
  getMaintainItem,
  updateMaintainItem,
} from 'devices/device/devices.service';
import { convertFileList, normFile } from 'files/files.service';
import { UploadInput } from 'files/UploadInput';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { updateDate } from 'pro/proModal/form.pro';
import { Dispatch, SetStateAction, useState } from 'react';
import { UpdateMaintainItemDto } from '../device/devices.interface';

const UpdateMaintainItemModal: React.FC<{
  deviceId: number;
  open: boolean;
  setOpen: Dispatch<
    SetStateAction<{
      open: boolean;
      id: number;
    }>
  >;
  refresh: () => void;
  id: number;
}> = ({ deviceId, open, setOpen, refresh, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateMaintainItem, {
    manual: true,
  });
  useRequest(getMaintainItem, {
    defaultParams: [deviceId, id],
    onSuccess: (v) => {
      form.setFieldsValue(updateDate(v));
      fileState1[1](convertFileList(v.beforeAttachments));
      fileState2[1](convertFileList(v.afterAttachments));
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen({ open: false, id: -1 });
  };
  const onFinish = async (value: UpdateMaintainItemDto) => {
    try {
      setAlert({ display: false });

      await runAsync(deviceId, id, value);
      message.success('修改成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };
  const fileState1 = useState<UploadFile[]>();
  const fileState2 = useState<UploadFile[]>();

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        name="update-maintain-item-form"
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="content" label="维修养护内容">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="类型">
          <Input />
        </Form.Item>
        <Form.Item name="date" label="实施时间">
          <DatePicker />
        </Form.Item>
        <Form.Item name="implementer" label="实施单位">
          <Input />
        </Form.Item>
        <Form.Item
          name="beforeAttachments"
          label="实施前照片"
          getValueFromEvent={normFile}
        >
          <UploadInput fileState={fileState1} />
        </Form.Item>
        <Form.Item
          name="afterAttachments"
          label="实施后照片"
          getValueFromEvent={normFile}
        >
          <UploadInput fileState={fileState2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateMaintainItemModal;
