import { StarFilled, StarOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { switchTop } from '../yunvideo.service';
import { Tooltip } from 'antd';

const SwitchTop: React.FC<{ id: number; top?: boolean }> = ({ id, top }) => {
  const [topState, setTopState] = useState<boolean>(!!top);

  return (
    <Tooltip mouseEnterDelay={1} title={topState ? '取消置顶' : '置顶'}>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          setTopState(!topState);
          switchTop(id);
        }}
      >
        {topState ? <StarFilled /> : <StarOutlined />}
      </a>
    </Tooltip>
  );
};
export default SwitchTop;
