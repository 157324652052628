import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadFile, UploadProps } from 'antd';
import type { RcFile } from 'antd/es/upload';
import { getAuthorization } from 'auth/AuthProvider';
import { notEmpty } from 'common/filter.helper';
import React, { useState } from 'react';
import { FileMetadataDto } from './files.interface';
import { FILE_UPLOAD_PATH, deleteFile } from './files.service';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const UploadImagesInput: React.FC<{
  onChange?: (e?: FileMetadataDto[]) => void;
  fileState?: [
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UploadFile<any>[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.Dispatch<React.SetStateAction<UploadFile<any>[] | undefined>>
  ];
}> = ({ onChange, fileState }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>();

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    fileState ? fileState[1](newFileList) : setFileList(newFileList);
    const metas = newFileList.map((f) => f.response).filter(notEmpty);
    onChange && onChange(metas);
  };

  const handleRemove = (file: UploadFile) => {
    deleteFile(file.response.deleteUrl);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );

  return (
    <>
      <Upload
        action={FILE_UPLOAD_PATH}
        listType="picture-card"
        fileList={fileState ? fileState[0] : fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        headers={{ Authorization: getAuthorization() }}
      >
        {fileState
          ? fileState[0] && fileState[0].length >= 5
            ? null
            : uploadButton
          : fileList && fileList.length >= 5
          ? null
          : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="img" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
