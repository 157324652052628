import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Row,
  Select,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { getColleagues } from 'auth/auth.service';
import Goback from 'common/Goback';
import { Device } from 'devices/device/devices.interface';
import { getDevice, getDevicesSelected } from 'devices/device/devices.service';
import { normFile } from 'files/files.service';
import { UploadImagesInput } from 'files/UploadImagesInput';
import { ProBreadcrumb } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWorkorderCategoriesSelected } from 'workorder/workorder-category/workorder-category.service';
import { CreateWorkorderDto } from '../workorder.interface';
import { createWorkorderByLeader } from '../workorder.service';

const WorkorderCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createWorkorderByLeader, {
    manual: true,
  });
  const { data: workorderCategories } = useRequest(
    getWorkorderCategoriesSelected
  );
  const { data: devices } = useRequest(getDevicesSelected);
  const { data: colleagues } = useRequest(getColleagues);
  const { runAsync: r2 } = useRequest(getDevice, {
    manual: true,
  });
  const navigate = useNavigate();

  const onCancel = () => {
    setAlert({ display: false });
  };
  const onFinish = async (value: CreateWorkorderDto) => {
    try {
      setAlert({ display: false });

      await runAsync(value);
      onCancel();
      form.resetFields();
      navigate(-1);
      message.success('新增成功!');
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };
  const onValuesChange = async ({ device }: { device: Device }) => {
    console.log(device);
    if (devices && device?.id) {
      const dev = await r2(device.id);
      console.log(dev);
      if (dev) {
        form.setFieldValue(['maintainer', 'id'], dev.maintainer.id);
      } else {
        form.setFieldValue(['maintainer', 'id'], 0);
      }
    }
  };

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">添加工单</h2>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: '880px' }}>
        <Card>
          <Form
            layout="vertical"
            form={form}
            preserve={false}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <FormAlert {...alert} />
            <Form.Item
              name="categoryIds"
              label="工单类型"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                placeholder="选择工单类型"
                allowClear
                options={
                  workorderCategories &&
                  workorderCategories.map((m) => ({
                    value: m.id,
                    label: m.name,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              name={['device', 'id']}
              label="设备"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="选择设备"
                allowClear
                options={
                  devices &&
                  devices.map((m) => ({
                    value: m.id,
                    label: m.name,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              name="startAt"
              label="实施时间"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="planEndAt"
              label="计划完成时间"
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name={['maintainer', 'id']}
              label="维护人员"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="选择维护人员"
                allowClear
                options={
                  colleagues &&
                  colleagues.map((m) => ({
                    value: m.id,
                    label: m.name,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              name="problem"
              label="问题描述"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="problemAttachments"
              label="附件"
              getValueFromEvent={normFile}
            >
              <UploadImagesInput />
            </Form.Item>
            <Row>
              <Col
                className="gutter-row"
                span={24}
                style={{ textAlign: 'right' }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  保存
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default WorkorderCreate;
