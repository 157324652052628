import { useLocation, useNavigate } from 'react-router-dom';

export const TempNavBtn: React.FC<{
  deviceId: number;
}> = ({ deviceId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(`${location.pathname}/${deviceId}/temp-dashboard`);
      }}
    >
      温控大盘
    </a>
  );
};
