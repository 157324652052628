import { useAntdTable } from 'ahooks';
import { useState } from 'react';
import { extraProps } from './table.pro';

export function useTableModal<T, CDto, UDto>(
  getAll: ({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }) => Promise<{
    total: number;
    list: T[];
  }>,
  getOne: (id: number) => Promise<T>,
  create: (value: CDto) => Promise<unknown>,
  update: (id: number, value: UDto) => Promise<unknown>,
  del: (id: number) => Promise<void>
) {
  const { tableProps, refresh } = useAntdTable(getAll, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );
  const tbProps = { ...tableProps, ...extraProps(tableProps) };
  const cuModalProps = {
    createState,
    updateState,
    setCreateState,
    setUpdateState,
    refresh,
    create,
    update,
    getOne,
  };

  return {
    aProps: { setUpdateState, refresh, del },
    caProps: { setCreateState, refresh },
    tbProps: tbProps,
    cuModalProps: cuModalProps,
    tableModalProps: {
      tbProps,
      cuModalProps,
    },
  };
}
