import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Leconfig,
  CreateLeconfigDto,
  UpdateLeconfigDto,
} from './leconfig.interface';
import { apiPrefix as api } from 'common/url.help';

export const getLeconfigs = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/leconfigs?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Leconfig>>(url));
  return { total: data.total, list: data.items };
};

export const getLeconfig = async (id: number) => {
  const url = api(`/video/leconfigs/${id}`);
  return getData(axios.get<Leconfig>(url));
};

export const deleteLeconfig = async (id: number) => {
  const url = api(`/video/leconfigs/${id}`);
  return getData(axios.delete<void>(url));
};

export const createLeconfig = async (value: CreateLeconfigDto) => {
  const url = api(`/video/leconfigs`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateLeconfig = async (id: number, value: UpdateLeconfigDto) => {
  const url = api(`/video/leconfigs/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

// 按照 localStorage leyun 两个缓存级别取数据
export const getKitToken = async (deviceId: string, channelId: string) => {
  const str = localStorage.getItem('le-kitTokens');
  if (str) {
    const map = JSON.parse(str);
    const key = `le-${deviceId}-${channelId}`;
    const value = map[key];
    if (value && value.expireTime > Date.now()) {
      return value.kitToken;
    }
  }

  const url = api(`/video/leconfigs/kittoken/${deviceId}/${channelId}`);
  const t = await getData(
    axios.get<{ kitToken: string; expireTime: number }>(url)
  );
  if (!t) {
    throw new Error('乐橙云配置错误！');
  }
  putCache(`le-${deviceId}-${channelId}`, t);
  return t.kitToken;
};

const putCache = (
  key: string,
  value: { kitToken: string; expireTime: number }
) => {
  const str = localStorage.getItem('le-kitTokens');
  let map: CacheDicType = {};
  if (str) {
    try {
      map = JSON.parse(str);
    } catch {}
  }
  map[key] = value;
  const newstr = JSON.stringify(map);
  localStorage.setItem('le-kitTokens', newstr);
};
type CacheDicType = { [key: string]: { kitToken: string; expireTime: number } };
