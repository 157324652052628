import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import { apiPrefix as api } from 'common/url.help';
import { Yunvideo } from './yunvideo.interface';

export const getAllYunvideo = async ({
  current = 1,
  pageSize = 100,
}: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/yunvideo?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Yunvideo>>(url));
  return { total: data.total, list: data.items };
};

export const switchTop = async (id: number) => {
  const url = api(`/video/yunvideo/top/${id}`);
  return getData(axios.get(url));
};
