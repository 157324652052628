import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Levideo,
  CreateLevideoDto,
  UpdateLevideoDto,
} from './levideo.interface';
import { apiPrefix as api } from 'common/url.help';

export const getAllLevideo = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/yunvideo/le?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Levideo>>(url));
  return { total: data.total, list: data.items };
};

export const getLevideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.get<Levideo>(url));
};

export const deleteLevideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.delete<void>(url));
};

export const createLevideo = async (value: CreateLevideoDto) => {
  const url = api(`/video/yunvideo/le`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateLevideo = async (id: number, value: UpdateLevideoDto) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const uploadScreenshot = ({
  fileName,
  base64,
}: {
  fileName: string;
  base64: string;
}) => {
  fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const fd = new FormData();
      const file = new File([blob], fileName);
      fd.append('image', file);

      // Let's upload the file
      // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
      const API_URL = '/files/uploads/screenshot';
      fetch(API_URL, { method: 'POST', body: fd })
        .then((res) => res.json())
        .then((res) => console.log(res));
    });
};
