import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import { apiPrefix as api } from 'common/url.help';
import {
  AlarmCategory,
  CreateAlarmCategoryDto,
  UpdateAlarmCategoryDto,
} from './alarm-category.interface';

export const getAlarmCategories = async ({
  current,
  pageSize,
}: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarms/alarm-categories?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<AlarmCategory>>(url));
  return { total: data.total, list: data.items };
};

export const getAlarmCategoriesSelected = async () => {
  const url = api('/alarms/alarm-categories/selected');
  return getData(axios.get<AlarmCategory[]>(url));
};

export const getAlarmCategory = async (id: number) => {
  const url = api(`/alarms/alarm-categories/${id}`);
  return getData(axios.get<AlarmCategory>(url));
};

export const deleteAlarmCategory = async (id: number) => {
  const url = api(`/alarms/alarm-categories/${id}`);
  return getData(axios.delete<void>(url));
};

export const createAlarmCategory = async (value: CreateAlarmCategoryDto) => {
  const url = api(`/alarms/alarm-categories`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateAlarmCategory = async (
  id: number,
  value: UpdateAlarmCategoryDto
) => {
  const url = api(`/alarms/alarm-categories/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
