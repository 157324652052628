import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import { CreatePositionDto } from '../position.interface';
import { createPosition } from '../position.service';

const CreatePositionModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  deviceId: number;
}> = ({ open, setOpen, refresh, deviceId }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createPosition, {
    manual: true,
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: CreatePositionDto) => {
    try {
      setAlert({ display: false });
      value.deviceId = deviceId;
      await runAsync(deviceId, value);
      message.success('新增成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="新增"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="位置" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="order" label="排序" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePositionModal;
