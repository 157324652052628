import { ReloadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { message, Modal, Space, Tooltip, Typography } from 'antd';
import { changePasswordByAdmin } from 'auth/auth.service';
import { Dispatch, SetStateAction, useState } from 'react';
const { Paragraph } = Typography;

function generatePsd() {
  var pass = '';
  var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 1; i <= 18; i++) {
    var char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

const ResetPasswordModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: number;
}> = ({ open, setOpen, id }) => {
  const [password, setPassword] = useState<string>(generatePsd());
  const { loading, runAsync } = useRequest(changePasswordByAdmin, {
    manual: true,
  });

  return (
    <Modal
      title="重置后的新密码如下"
      className="reset-password-modal"
      open={open}
      onOk={async () => {
        try {
          await runAsync(id, { password });
          setOpen(false);
          message.success('重置成功');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          message.error('重置失败！');
        }
      }}
      confirmLoading={loading}
      onCancel={() => setOpen(false)}
    >
      <Space
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Paragraph copyable>{password}</Paragraph>
        <Tooltip title="换一个">
          <ReloadOutlined onClick={() => setPassword(generatePsd())} />
        </Tooltip>
      </Space>
    </Modal>
  );
};

export default ResetPasswordModal;
