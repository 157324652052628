import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './breadcrumb.css';
import { breadcrumbObjects as metaBreadcrumbObjects } from 'config/path';

export const ProBreadcrumb2: React.FC = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const map: { snippet: string; value: string }[] = [];
  const bos = metaBreadcrumbObjects.filter((meta) => {
    const metaPathSnippets = meta.path.split('/').filter((i) => i);
    if (metaPathSnippets.length !== pathSnippets.length) {
      return false;
    }

    for (let i = 0; i < metaPathSnippets.length; i++) {
      if (metaPathSnippets[i].startsWith(':')) {
        map.push({ snippet: metaPathSnippets[i], value: pathSnippets[i] });
      } else if (metaPathSnippets[i] !== pathSnippets[i]) {
        return false;
      }
    }
    return true;
  });
  if (bos.length === 0) return <>Error 路径不匹配！</>;
  const matchedBreadcrumbObject = bos[0];

  const rp = (path: string) => {
    if (!map.length) return path;
    for (const item of map) {
      path = path.replace(item.snippet, item.value);
    }
    return path;
  };

  const temps: { path: string; name: string }[] = [];
  const doloop = (bo2: BreadcrumbObject) => {
    temps.unshift({
      name: bo2.name,
      path: rp(bo2.path),
    });
    bo2.parent && doloop(bo2.parent);
  };
  doloop(matchedBreadcrumbObject);

  const breadcrumbItems = temps.map((t, i, { length }) => {
    return {
      key: t.path,
      title: i === length - 1 ? t.name : <Link to={t.path}>{t.name}</Link>,
    };
  });

  return (
    <div className="content-breadcrumb-wrap">
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
};
