import { Tabs, Typography } from 'antd';
import NormalLoginForm from 'auth/login/NormalLoginForm';
import PhoneLoginForm from 'auth/login/PhoneLoginForm';
import { useTitle } from 'config/useTitleHook';
import './index.css';

const { Title, Text, Link } = Typography;
/*
登录页面的关键要素
- 登录按钮的加载动画
- 登录成功后跳转回源地址
- 验证成功后保存 token
- 登录失败提示失败原因
*/
const Login: React.FC = () => {
  useTitle();

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: 'url(/bgimg1.jpg)',
      }}
    >
      <div className="login-top">
        <div className="login-logo">
          <img
            src="/chenan_logo.png"
            alt="星辰智造"
            style={{ width: '200px' }}
          />
        </div>
        <Title level={4} style={{ paddingTop: '8px', fontWeight: 'normal' }}>
          登录
        </Title>
      </div>
      <div className="login-content">
        <div className="login-adv">
          <Title level={1}>重新定义热处理</Title>
          <Title level={3}>提供热处理整体解决方案</Title>
        </div>
        <div className="login-form-warp">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `账号登录`,
                children: <NormalLoginForm />,
              },
              {
                key: '2',
                label: `手机登录`,
                children: <PhoneLoginForm />,
              },
            ]}
          />
        </div>
      </div>
      <div className="login-buttom">
        <div className="login-buttom-remark">
          <Text type="secondary">
            <Link
              href="https://www.beianx.cn/search/cz-calculus.com"
              target="_blank"
              className="login-buttom-remark-beian"
            >
              苏ICP备2023022771号-2
            </Link>{' '}
            | 常州晨安智能装备科技有限公司版权所有 |
            常州小梨云科技有限公司提供技术支持
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Login;
