import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Solution,
  CreateSolutionDto,
  UpdateSolutionDto,
  CreateFailureCategoryDto,
  FailureCategory,
  UpdateFailureCategoryDto,
} from './solution.interface';
import { apiPrefix as api } from 'common/url.help';

export const getSolutions = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/solutions?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Solution>>(url));
  return { total: data.total, list: data.items };
};

export const getSolutionsSelected = async () => {
  const url = api('/solutions/selected');
  return getData(axios.get<Solution[]>(url));
};

export const getSolution = async (id: number) => {
  const url = api(`/solutions/${id}`);
  return getData(axios.get<Solution>(url));
};

export const deleteSolution = async (id: number) => {
  const url = api(`/solutions/${id}`);
  return getData(axios.delete<void>(url));
};

export const createSolution = async (value: CreateSolutionDto) => {
  const url = api(`/solutions`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateSolution = async (id: number, value: UpdateSolutionDto) => {
  const url = api(`/solutions/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

//-------------FailureCategory------------
export const getFailureCategories = async ({
  current,
  pageSize,
}: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api(
    '/solutions/failure-categories?' + qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<FailureCategory>>(url));
  return { total: data.total, list: data.items };
};

export const getFailureCategoriesSelected = async () => {
  const url = api('/solutions/failure-categories/selected');
  return getData(axios.get<FailureCategory[]>(url));
};

export const getFailureCategory = async (id: number) => {
  const url = api(`/solutions/failure-categories/${id}`);
  return getData(axios.get<FailureCategory>(url));
};

export const deleteFailureCategory = async (id: number) => {
  const url = api(`/solutions/failure-categories/${id}`);
  return getData(axios.delete<void>(url));
};

export const createFailureCategory = async (
  value: CreateFailureCategoryDto
) => {
  const url = api(`/solutions/failure-categories`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateFailureCategory = async (
  id: number,
  value: UpdateFailureCategoryDto
) => {
  const url = api(`/solutions/failure-categories/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
