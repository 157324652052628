import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  CreatePositionDto,
  Position,
  UpdatePositionDto,
} from './position.interface';

export const getPositions = async ({
  deviceId,
  current,
  pageSize,
}: RequestParms & {
  deviceId: number;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
    order: 'ASC',
    sort: 'order',
  };
  const url = api(
    `/devices/${deviceId}/positions?` + qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<Position>>(url));
  return { total: data.total, list: data.items };
};

export const getPositionsSelected = async (deviceId: number) => {
  const url = api(`/devices/${deviceId}/positions/selected`);
  return getData(axios.get<Position[]>(url));
};

export const getPosition = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/positions/${id}`);
  return getData(axios.get<Position>(url));
};

export const deletePosition = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/positions/${id}`);
  return getData(axios.delete<void>(url));
};

export const createPosition = async (
  deviceId: number,
  value: CreatePositionDto
) => {
  const url = api(`/devices/${deviceId}/positions`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updatePosition = async (
  deviceId: number,
  id: number,
  value: UpdatePositionDto
) => {
  const url = api(`/devices/${deviceId}/positions/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
