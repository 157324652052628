import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import { StatisticsResult } from './val-history.interface';
import qs from 'qs';

export const getStatisticsResult = async (requestParms: {
  tagId: number;
  timeDimId: number;
  startAt?: string;
  endAt?: string;
}) => {
  const url = api('/val-histories?' + qs.stringify(requestParms));
  return getData(axios.get<StatisticsResult[]>(url));
};
