import { Modal } from 'antd';
import { getKitToken } from 'video/levideo/leconfig/leconfig.service';
import { getLevideo } from '../levideo.service';
import './index.css';

const LevideoModal: React.FC<{
  state: [
    {
      open: boolean;
      id: number;
    },
    React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        id: number;
      }>
    >
  ];
}> = ({ state: [{ open, id }, setOpen] }) => {
  const onLoad = async () => {
    const { deviceId, channelId } = await getLevideo(id);
    const kitToken = await getKitToken(deviceId, channelId.toString());

    const iframeWrap = document.querySelector(
      '#le-video-container-wrap'
    ) as HTMLIFrameElement;
    const parentData = {
      deviceId,
      channelId,
      kitToken,
    };

    iframeWrap.contentWindow?.postMessage(parentData, '*');
  };

  const onCancel = () => {
    setOpen((v) => ({ ...v, open: false }));
  };

  return (
    <Modal
      title="播放视频"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
      className="video-modal"
    >
      <iframe
        id="le-video-container-wrap"
        title="le-video"
        src="/le-video.html"
        width={752}
        height={424}
        onLoad={onLoad}
      ></iframe>
    </Modal>
  );
};

export default LevideoModal;
