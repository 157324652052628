import dayjs from 'dayjs';

export const dayjsfmt = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm') : '';
};
export const dayjsfmt1 = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : '';
};
export const dayjsfmt3 = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '';
};
