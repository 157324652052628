import { useRequest } from 'ahooks';
import {
  Badge,
  Card,
  Descriptions,
  Empty,
  Tag,
  type DescriptionsProps,
} from 'antd';
import Goback from 'common/Goback';
import { ProBreadcrumb, ProSpin } from 'pro';
import { Link, useParams } from 'react-router-dom';
import { getAlarmConfig } from '../alarm-config.service';

const AlarmConfigDetail: React.FC = () => {
  const { configId } = useParams();
  if (!configId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(configId);
  const { data, loading } = useRequest(getAlarmConfig, {
    defaultParams: [id],
    onSuccess: (v) => {},
  });

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: '配置名称',
      children: data?.name,
      span: 3,
    },
    {
      key: '2',
      label: '报警类型',
      children: data?.alarmCategory && (
        <Tag color="blue" key={data.alarmCategory.id}>
          {data.alarmCategory.name}
        </Tag>
      ),
      span: 3,
    },
    {
      key: '3',
      label: '启用配置',
      children:
        data &&
        (data.enabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        )),
      span: 3,
    },
    {
      key: '4',
      label: '启用通知',
      children:
        data &&
        (data.noticeEnabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        )),
      span: 3,
    },
    {
      key: '5',
      label: '报警条件',
      children:
        data?.conditions &&
        data.conditions
          .map((c) => `关联变量值 ${c.key} ${c.value}`)
          .join(' OR '),
      span: 3,
    },
    {
      key: '6',
      label: '通知人员',
      children:
        data?.noticeTo &&
        data.noticeTo
          .map((u) => u.username)
          .filter((e) => e)
          .join(' '),
      span: 3,
    },
    {
      key: '7',
      label: '自动派发',
      children:
        data &&
        (data.autoDispatchEnabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        )),
      span: 3,
    },
    {
      key: '8',
      label: '派发人员',
      children:
        data?.maintenanceTo &&
        data.maintenanceTo
          .map((u) => u.username)
          .filter((e) => e)
          .join(' '),
      span: 3,
    },
    {
      key: '9',
      label: '关联方案',
      children: data?.solutions && data.solutions.length > 0 && (
        <ul style={{ paddingLeft: 16 }}>
          {data.solutions.map((s) => (
            <li key={s.id}>
              <Link to={`/al/solutions/${s.id.toString()}`}>{s.name}</Link>
            </li>
          ))}
        </ul>
      ),

      span: 3,
    },
  ];

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">解决方案</h2>
          </div>
        </div>
      </div>
      <Card style={{ maxWidth: '880px', marginTop: '24px' }}>
        {loading ? (
          <ProSpin />
        ) : data ? (
          <Descriptions title={data.name} items={items} />
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};

export default AlarmConfigDetail;
