import { Row, Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { permissions } from 'config/permissions';
import { Dispatch, SetStateAction, useState } from 'react';

const PermissionCheckBox: React.FC<{
  checkListValue: CheckboxValueType[];
  setCheckListValue: Dispatch<SetStateAction<CheckboxValueType[]>>;
}> = ({ checkListValue, setCheckListValue }) => {
  //const [checkListValue, setCheckListValue] = useState<CheckboxValueType[]>([]); //defaultCheckedList
  const [indeterminate, setIndeterminate] = useState(
    () => checkListValue.length > 0
  );
  const [checkAll, setCheckAll] = useState(false);

  const plainOptions = permissions.map((o) => ({
    label: o.name,
    value: o.code,
  }));

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckListValue(e.target.checked ? plainOptions.map((p) => p.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckListValue(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  return (
    <div className="permissions-checkbox">
      <Row>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          className="permissions-checkbox-all"
        >
          全选
        </Checkbox>
      </Row>
      <Row>
        <Checkbox.Group
          options={plainOptions}
          value={checkListValue}
          onChange={onChange}
        />
      </Row>
    </div>
  );
};
export default PermissionCheckBox;
