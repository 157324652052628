import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
const { confirm } = Modal;

export const RegisterClientBtn: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <a
      href="/"
      onClick={async (e) => {
        e.preventDefault();
        onClick();
      }}
    >
      绑定网关
    </a>
  );
};

export const UnRegisterClientBtn: React.FC<{
  onDel: () => void;
}> = ({ onDel }) => {
  return (
    <a
      href="/"
      onClick={async (e) => {
        e.preventDefault();
        confirm({
          title: '你想要取消关联网关吗!',
          icon: <ExclamationCircleOutlined />,
          okText: '确定',
          okType: 'danger' as LegacyButtonType,
          onOk: onDel,
          onCancel() {},
        });
      }}
    >
      网关解绑
    </a>
  );
};
