import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dayjsfmt } from 'common/dayjsfmt';
import { RefreshButton, extraProps } from 'pro/table.pro';
import { Operlog } from '../logs.interface';
import { getOperlogs } from '../logs.service';
import { ProBreadcrumb } from 'pro';

const OperlogList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getOperlogs, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });

  const columns: ColumnsType<Operlog> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '时间',
      render: (_, { date }) => dayjsfmt(date),
    },
    {
      title: '用户名',
      dataIndex: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '内容',
      dataIndex: 'msg',
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">写入数据记录</h2>
          <Space>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default OperlogList;
