import { SwapRightOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Badge, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dayjsfmt } from 'common/dayjsfmt';
import {
  DeleteButton,
  DetailNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alarm } from '../alarm.interface';
import { deleteAlarm, getAlarms3 } from '../alarm.service';

const AlarmList3: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getAlarms3, {
    //refreshOnWindowFocus: true,
  });
  const [now, setNow] = useState<string>(dayjsfmt(new Date()));
  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setNow(dayjsfmt(new Date()));
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [now]);
  const columns: ColumnsType<Alarm> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '设备',
      render: (_, { device }) => (
        <Link to={`/dm/devices/${device.id.toString()}`}>
          {device.name + (device.no ? `(${device.no})` : '')}
        </Link>
      ),
    },
    {
      title: '报警状态',
      render: (_, { status }) =>
        (status === '报警中' && <Badge status="error" text="报警中" />) ||
        (status === '自动恢复' && <Badge status="default" text="自动恢复" />),
    },
    {
      title: '报警类型',
      render: (_, { alarmCategories }) =>
        alarmCategories &&
        alarmCategories.map((cate) => (
          <Tag color="red" key={cate.id}>
            {cate.name}
          </Tag>
        )),
    },
    {
      title: '持续时间',
      render: (_, { startAt, endAt }) =>
        endAt ? (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {dayjsfmt(endAt)}
          </Space>
        ) : (
          <Space>
            {dayjsfmt(startAt)}
            <SwapRightOutlined />
            {now}
          </Space>
        ),
    },
    {
      title: '通知人员',
      render: (_, { noticeTo }) =>
        noticeTo &&
        noticeTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
    {
      title: '派发人员',
      render: (_, { maintenanceTo }) =>
        maintenanceTo &&
        maintenanceTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          <DeleteButton onDel={() => deleteAlarm(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <div className="content-toolbar-container">
          <div className="content-toolbar-title"></div>
          <Space>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default AlarmList3;
