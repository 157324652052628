import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { User } from 'auth/auth.interface';
import { deleteUser } from 'auth/auth.service';
import ResetPasswordModal from 'auth/user/ResetPassword';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { getCompaniesUsers } from '../company.service';
import CreateUserModal from './Create';
import UpdateUserModal from './Update';

const CompanyUser: React.FC<{ companyId: number }> = ({ companyId }) => {
  const { tableProps, refresh } = useAntdTable(getCompaniesUsers, {
    defaultParams: [{ companyId, current: 1, pageSize: 10 }],
    //refreshOnWindowFocus: true,
  });
  const [openReset, setOpenReset] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{
    open: boolean;
    id: number;
  }>({
    open: false,
    id: -1,
  });
  const [userId, setUserId] = useState(0);
  const columns: ColumnsType<User> = [
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone',
    },
    // {
    //   title: '角色',
    //   key: 'roles',
    //   dataIndex: 'roles',
    //   render: (_, { roles }) => (
    //     <>
    //       {roles
    //         ? roles.map((role) => {
    //             return <Tag key={role.id}>{role.name}</Tag>;
    //           })
    //         : '-'}
    //     </>
    //   ),
    // },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton
            onUpdate={() => {
              setUserId(id);
              setUpdateState({ open: true, id });
            }}
          />
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              setUserId(id);
              setOpenReset(true);
            }}
          >
            重置密码
          </a>
          <DeleteButton onDel={() => deleteUser(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <div className="content-toolbar-container">
          <span></span>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {openReset ? (
        <ResetPasswordModal
          open={openReset}
          setOpen={setOpenReset}
          id={userId}
        />
      ) : (
        <></>
      )}
      {createState ? (
        <CreateUserModal
          companyId={companyId}
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
        />
      ) : (
        <></>
      )}
      {updateState.open ? (
        <UpdateUserModal
          open={updateState.open}
          setOpen={setUpdateState}
          refresh={refresh}
          id={userId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyUser;
