import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { useState } from 'react';
import { UpdateLevideoDto } from '../levideo.interface';
import { getLevideo, updateLevideo } from '../levideo.service';

const UpdateLevideoModal: React.FC<{
  updateState: [
    {
      open: boolean;
      id: number;
    },
    React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        id: number;
      }>
    >
  ];
  refresh: () => void;
}> = ({ updateState: [{ open, id }, setOpen], refresh }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateLevideo, { manual: true });
  useRequest(getLevideo, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen((v) => ({ ...v, open: false }));
  };
  const onFinish = async (value: UpdateLevideoDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      message.success('修改成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="名称">
          <Input />
        </Form.Item>
        <Form.Item
          name="deviceId"
          label="设备序列号"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="channelId" label="通道号" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="url" label="预览地址">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateLevideoModal;
