import { Dispatch, SetStateAction } from 'react';
import CreateModal from './CreateModal';
import UpdateModal from './UpdateModal';

interface PropsType<CDto, UDto, Result> {
  createState: boolean;
  setCreateState: Dispatch<SetStateAction<boolean>>;
  create: (value: CDto) => Promise<unknown>;

  updateState: {
    open: boolean;
    id: number;
  };
  setUpdateState: Dispatch<
    SetStateAction<{
      open: boolean;
      id: number;
    }>
  >;
  refresh: () => void;
  update: (id: number, value: UDto) => Promise<unknown>;
  getOne: (id: number) => Promise<Result>;
  normRes?: ((v: Result) => void) | undefined;
  children: React.ReactNode;
}

function ProModal<CDto, UDto, Result>({
  createState,
  setCreateState,
  create,
  updateState,
  setUpdateState,
  refresh,
  update,
  getOne,
  normRes,
  children,
}: React.PropsWithChildren<PropsType<CDto, UDto, Result>>) {
  return (
    <>
      {createState ? (
        <CreateModal<CDto>
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
          service={create}
        >
          {children}
        </CreateModal>
      ) : (
        <></>
      )}
      {updateState.open ? (
        <UpdateModal<UDto, Result>
          open={updateState.open}
          setOpen={setUpdateState}
          refresh={refresh}
          update={update}
          getOne={getOne}
          normRes={normRes}
          id={updateState.id}
        >
          {children}
        </UpdateModal>
      ) : (
        <></>
      )}
    </>
  );
}
export default ProModal;
