import { useAntdTable } from 'ahooks';
import { Form, Input, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProBreadcrumb, ProModal } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import {
  WorkorderCategory,
  CreateWorkorderCategoryDto,
  UpdateWorkorderCategoryDto,
} from '../workorder-category.interface';
import {
  createWorkorderCategory,
  deleteWorkorderCategory,
  getWorkorderCategories,
  getWorkorderCategory,
  updateWorkorderCategory,
} from '../workorder-category.service';

const WorkorderCategoryList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getWorkorderCategories, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );

  const columns: ColumnsType<WorkorderCategory> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
          <DeleteButton
            onDel={() => deleteWorkorderCategory(id).then(refresh)}
          />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">工单类型</h2>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <ProModal<
        CreateWorkorderCategoryDto,
        UpdateWorkorderCategoryDto,
        WorkorderCategory
      >
        createState={createState}
        updateState={updateState}
        setCreateState={setCreateState}
        setUpdateState={setUpdateState}
        create={createWorkorderCategory}
        update={updateWorkorderCategory}
        getOne={getWorkorderCategory}
        refresh={refresh}
      >
        <Form.Item name="name" label="名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </ProModal>
    </>
  );
};

export default WorkorderCategoryList;
