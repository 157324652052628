import './index.css';

type TemperatureRangerType = {
  current: number;
  lower: number;
  upper: number;
  minMark?: number;
  maxMark?: number;
};

const TemperatureRanger: React.FC<TemperatureRangerType> = ({
  current,
  lower,
  upper,
  minMark = 0,
  maxMark = 1000,
}) => {
  if (current > maxMark) {
    current = maxMark;
  }
  const pointerOffset = Math.round((current / maxMark) * 100) - 1;
  const progressLeftOffset = Math.round((lower / maxMark) * 100);
  const progressRightOffset = Math.round(100 - (upper / maxMark) * 100);
  return (
    <>
      <div style={{ marginBottom: 4, color: 'rgba(0, 0, 0, 0.6)' }}>
        {current >= lower && current <= upper
          ? '处于最佳工作温度区间'
          : current < lower
          ? `低于（< ${lower}°）最佳工作温度下限`
          : `高于（> ${upper}°）最佳工作温度上限`}
      </div>
      <div className="ant-progress ant-progress-status-normal ant-progress-line ant-progress-default temp-range">
        <div
          className="temp-pointer"
          style={{ left: pointerOffset + '%', width: '2px' }}
        >
          |
        </div>
        <div
          className="ant-progress-outer temp-range-outer"
          style={{ width: '100%', height: '8px' }}
        >
          <div className="ant-progress-inner temp-range-inner">
            <div
              className="ant-progress-bg temp-range-bg"
              style={{
                clipPath: `inset(0px ${progressRightOffset}% 0px ${progressLeftOffset}% round 100px)`,
              }}
            ></div>
          </div>
        </div>
        <div className="temp-range-mark">
          <span>{minMark}</span>
          <span>{maxMark}°</span>
        </div>
      </div>
    </>
  );
};
export default TemperatureRanger;
