import { useAntdTable } from 'ahooks';
import { Alert, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProSpin } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { getLeconfigs } from 'video/levideo/leconfig/leconfig.service';
import { Levideo } from '../levideo.interface';
import { deleteLevideo, getAllLevideo } from '../levideo.service';
import CreateLevideoModal from './Create';
import UpdateLevideoModal from './Update';
import LevideoModal from './Video';

const LevideoList: React.FC = () => {
  const { loading, data } = useAntdTable(getLeconfigs, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const { tableProps, refresh } = useAntdTable(getAllLevideo, {
    //refreshOnWindowFocus: true,
  });
  const createState = useState(false);
  const updateState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const videoState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });

  const columns: ColumnsType<Levideo> = [
    {
      title: '所属公司',
      dataIndex: ['company', 'name'],
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '设备序列号',
      dataIndex: 'deviceId',
    },
    {
      title: '通道号',
      dataIndex: 'channelId',
    },
    {
      title: '预览地址',
      dataIndex: 'url',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              videoState[1]({ open: true, id });
            }}
          >
            播放
          </a>
          <UpdateButton onUpdate={() => updateState[1]({ open: true, id })} />
          <DeleteButton onDel={() => deleteLevideo(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return loading ? (
    <ProSpin />
  ) : data?.total === 0 ? (
    <Alert
      message="请配置初始参数！"
      type="error"
      style={{ marginTop: '16px' }}
    />
  ) : (
    <>
      <div className="content-toolbar">
        <Alert
          message={
            <>
              设备参数可从乐橙云官网获取：
              <Space>
                <a
                  href="https://open.imou.com/consoleNew/vas/deviceIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  获取 设备序列号 通道号
                </a>
              </Space>
            </>
          }
          type="info"
          style={{ marginTop: '16px' }}
        />
        <div className="content-toolbar-container">
          <div className="content-toolbar-title"></div>
          <Space>
            <CreateButton onCreate={() => createState[1](true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <CreateLevideoModal createState={createState} refresh={refresh} />
      {updateState[0].open && (
        <UpdateLevideoModal updateState={updateState} refresh={refresh} />
      )}
      {videoState[0].open && <LevideoModal state={videoState} />}
    </>
  );
};

export default LevideoList;
