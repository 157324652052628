import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { getAlarmCategoriesSelected } from 'alarm/category/alarm-category.service';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd';
import { getColleagues } from 'auth/auth.service';
import Goback from 'common/Goback';
import { ProBreadcrumb, ProSpin } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSolutionsSelected } from 'workorder/solution/solution.service';
import { UpdateAlarmConfigDto } from '../alarm-config.interface';
import { getAlarmConfig, updateAlarmConfig } from '../alarm-config.service';

const AlarmConfigUpdate: React.FC = () => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateAlarmConfig, {
    manual: true,
  });
  const { data: alarmCategories, loading: l2 } = useRequest(
    getAlarmCategoriesSelected
  );
  const { data: solutions, loading: l3 } = useRequest(getSolutionsSelected);
  const { data: users, loading: l4 } = useRequest(getColleagues);
  const navigate = useNavigate();

  const { configId } = useParams();
  if (!configId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(configId);
  const { loading: l1 } = useRequest(getAlarmConfig, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
      v.noticeTo &&
        form.setFieldsValue({
          noticeToIds: v.noticeTo.map((r) => r.id),
        });
      v.maintenanceTo &&
        form.setFieldsValue({
          maintenanceToIds: v.maintenanceTo.map((r) => r.id),
        });
      v.solutions &&
        form.setFieldsValue({
          solutionIds: v.solutions.map((r) => r.id),
        });
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
  };
  const onFinish = async (value: UpdateAlarmConfigDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      onCancel();
      form.resetFields();
      navigate(-1);
      message.success('编辑成功!');
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">编辑报警配置</h2>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: '880px' }}>
        {l1 || l2 || l3 || l4 ? (
          <ProSpin />
        ) : (
          <Card style={{ padding: '24px 0' }}>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              form={form}
              preserve={false}
              onFinish={onFinish}
              initialValues={{
                enabled: true,
                noticeEnabled: true,
                autoDispatchEnabled: true,
              }}
            >
              <FormAlert {...alert} />
              <Form.Item
                name="name"
                label="配置名称"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['alarmCategory', 'id']}
                label="报警类型"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="选择报警类型"
                  allowClear
                  options={
                    alarmCategories &&
                    alarmCategories.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item
                name="enabled"
                label="启用配置"
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Switch />
              </Form.Item>
              <Row className="ant-form-item ">
                <Col span={4} className="ant-form-item-label">
                  <label className="ant-form-item-required">报警条件</label>
                </Col>
                <Col span={16}>
                  <Form.List name="conditions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                          >
                            <span>关联变量值</span>
                            <Form.Item
                              {...restField}
                              name={[name, 'key']}
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="选择比较符"
                                style={{ width: 100 }}
                                allowClear
                                options={[
                                  { lable: '>=', value: '>=' },
                                  { lable: '>', value: '>' },
                                  { lable: '=', value: '=' },
                                  { lable: '<', value: '<' },
                                  { lable: '<=', value: '<=' },
                                ]}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'value']}
                              rules={[{ required: true }]}
                            >
                              <Input
                                placeholder="报警限值"
                                style={{ width: 180 }}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            增加条件 (OR)
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>

              <Form.Item name="remark" label="备注">
                <Input />
              </Form.Item>
              <Form.Item
                name="noticeEnabled"
                label="启用通知"
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item name="noticeToIds" label="通知人员">
                <Select
                  mode="multiple"
                  placeholder="选择通知人员"
                  allowClear
                  options={
                    users &&
                    users.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                name="autoDispatchEnabled"
                label="自动派发"
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item name="maintenanceToIds" label="维护人员">
                <Select
                  mode="multiple"
                  placeholder="选择维护人员"
                  allowClear
                  options={
                    users &&
                    users.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item name="solutionIds" label="关联解决方案">
                <Select
                  mode="multiple"
                  placeholder="选择解决方案"
                  allowClear
                  options={
                    solutions &&
                    solutions.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Row>
                <Col
                  className="gutter-row"
                  span={24}
                  style={{ textAlign: 'right' }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    保存
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default AlarmConfigUpdate;
