import { Tabs } from 'antd';
import Goback from 'common/Goback';
import { useParams } from 'react-router-dom';
import { ProBreadcrumb } from 'pro';
import OrgUsers from './OrgUsers';
import { useRequest } from 'ahooks';
import { getInCompany } from './in-company.service';

const InCompanyDetailFc: React.FC = () => {
  const { companyId } = useParams();
  const id = Number(companyId);
  const { data } = useRequest(getInCompany, {
    defaultParams: [id],
  });
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div
          className="content-toolbar-container"
          style={{ paddingBottom: '8px' }}
        >
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">{data?.name}明细配置</h2>
          </div>
        </div>
      </div>
      <div className="content-tabs-wrap">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: `组织架构`,
              children: <OrgUsers companyId={Number(companyId)} />,
            },
            {
              key: '2',
              label: `设备管理`,
              children: '',
            },
          ]}
        />
      </div>
    </>
  );
};

export default InCompanyDetailFc;
