import { Card, Spin } from 'antd';
import './index.css';

export const ProSpin: React.FC = () => {
  return (
    <Card className="spin-wrap">
      <Spin />
    </Card>
  );
};
