import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import { apiPrefix as api } from 'common/url.help';
import { MessageLog } from './msglogs.interface';

export const getMessageLogs = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarms/logs?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<MessageLog>>(url));
  return { total: data.total, list: data.items };
};
