import { PlusOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Alert, Button, Form, Input, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProModal } from 'pro';
import {
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import {
  CreateYsconfigDto,
  UpdateYsconfigDto,
  Ysconfig,
} from '../ysconfig.interface';
import {
  createYsconfig,
  deleteYsconfig,
  getYsconfig,
  getYsconfigs,
  updateYsconfig,
} from '../ysconfig.service';

const YsconfigList: React.FC = () => {
  const { tableProps, refresh, data } = useAntdTable(getYsconfigs, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );

  const columns: ColumnsType<Ysconfig> = [
    {
      title: 'AppKey',
      dataIndex: 'appKey',
    },
    {
      title: 'Secret',
      dataIndex: 'appSecret',
    },
    {
      title: 'AccessToken',
      dataIndex: 'accessToken',
    },
    {
      title: '模板ID',
      dataIndex: 'templateId',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
          <DeleteButton onDel={() => deleteYsconfig(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <Alert
          message={
            <>
              初始化参数可从萤石云官网获取：
              <Space>
                <a
                  href="https://open.ys7.com/console/application.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  获取 AppKey Secret AccessToken
                </a>
                <a
                  href="https://open.ys7.com/console/ezuikit/template.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  获取 模板ID
                </a>
              </Space>
            </>
          }
          type="info"
          style={{ marginTop: '16px' }}
        />
        <div className="content-toolbar-container">
          <div className="content-toolbar-title"></div>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateState(true)}
              disabled={data && data.total > 0}
            >
              新增
            </Button>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <ProModal<CreateYsconfigDto, UpdateYsconfigDto, Ysconfig>
        createState={createState}
        updateState={updateState}
        setCreateState={setCreateState}
        setUpdateState={setUpdateState}
        create={createYsconfig}
        update={updateYsconfig}
        getOne={getYsconfig}
        refresh={refresh}
      >
        <Form.Item name="appKey" label="AppKey" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="appSecret" label="Secret" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="accessToken" label="AccessToken">
          <Input />
        </Form.Item>
        <Form.Item name="templateId" label="模板ID">
          <Input />
        </Form.Item>
      </ProModal>
    </>
  );
};

export default YsconfigList;
