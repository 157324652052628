import { Line } from '@ant-design/plots';
import { useRequest } from 'ahooks';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { dayjsfmt1, dayjsfmt3 } from 'common/dayjsfmt';
import { getDevicesSelected } from 'devices/device/devices.service';
import { Tag } from 'devices/tag/tag.interface';
import { getTagsSelected } from 'devices/tag/tag.service';
import { ProBreadcrumb } from 'pro';
import { RefreshButton } from 'pro/table.pro';
import { useState } from 'react';
import { StatisticsResult, timeDimensions } from '../val-history.interface';
import { getStatisticsResult } from '../val-history.service';

const ValHistoryList: React.FC = () => {
  const [form] = Form.useForm();
  const { data: devices } = useRequest(getDevicesSelected, {
    onSuccess: (v) => {},
  });
  const [tags, setTags] = useState<Tag[]>();
  const [hidden, setHidden] = useState(true);
  const { runAsync: getTags } = useRequest(getTagsSelected, {
    manual: true,
  });

  const {
    data,
    runAsync: getRes,
    refresh,
  } = useRequest(getStatisticsResult, {
    manual: true,
    pollingInterval: 1000 * 60,
    onSuccess: (v) => {},
  });
  const columns: ColumnsType<StatisticsResult> = [
    {
      title: '时间',
      dataIndex: 'date',
      render: (_, { date }) => dayjsfmt3(date),
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      defaultSortOrder: 'descend',
      sortDirections: ['descend'],
    },
    {
      title: '值',
      dataIndex: 'value',
    },
  ];

  const onValuesChange = async (
    { deviceId }: { deviceId: number },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
  ) => {
    if (deviceId) {
      const ts = await getTags(deviceId);
      form.setFieldsValue({ tagId: undefined });
      setTags(ts);
    } else {
      setHidden(values.timeDimId !== 9);
      if (values.tagId && values.timeDimId && values.timeDimId !== 9) {
        getRes({ tagId: values.tagId, timeDimId: values.timeDimId });
      } else if (
        values.tagId &&
        values.timeDimId &&
        values.timeDimId === 9 &&
        values.times &&
        values.times.length === 2
      ) {
        getRes({
          tagId: values.tagId,
          timeDimId: values.timeDimId,
          startAt: dayjsfmt1(values.times[0]),
          endAt: dayjsfmt1(values.times[1]),
        });
      }
    }
  };
  const config = {
    data: data || [],
    height: 300,
    xField: 'date',
    yField: 'value',
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    meta: {
      value: { alias: '值' },
      date: {
        alias: '时间',
        formatter: (v: string) => dayjsfmt3(v),
      },
    },
  };

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">历史数据</h2>
          <Space>
            <Tooltip title="自动刷新">
              <Switch
                checkedChildren="启用"
                unCheckedChildren="关闭"
                defaultChecked
                disabled
              />
            </Tooltip>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Card>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Form
              form={form}
              layout="inline"
              initialValues={{ timeDimId: 1 }}
              onValuesChange={onValuesChange}
            >
              <Form.Item name="deviceId">
                <Select
                  style={{ width: 120 }}
                  placeholder="选择设备"
                  options={
                    devices &&
                    devices.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item name="tagId">
                <Select
                  style={{ width: 120 }}
                  placeholder="选择变量"
                  options={
                    tags &&
                    tags.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item name="timeDimId">
                <Select
                  style={{ width: 120 }}
                  listHeight={300}
                  placeholder="选择时间"
                  options={timeDimensions.map((m) => ({
                    value: m.id,
                    label: m.timeRanges,
                  }))}
                />
              </Form.Item>
              <Form.Item name="times" hidden={hidden}>
                <DatePicker.RangePicker />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <h3>历史数据趋势</h3>
            <Line {...config} />
          </Col>
          <Col span={24}>
            <h3>历史数据</h3>
            <Table columns={columns} size="small" dataSource={data} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ValHistoryList;
