import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Modal, TablePaginationConfig, Tooltip } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
const { confirm } = Modal;

const defaultOptions: {
  rowKey: string;
  pagination?: TablePaginationConfig;
} = {
  rowKey: 'id',
};

export const extraProps = ({
  pagination,
}: {
  pagination: TablePaginationConfig;
}) => {
  defaultOptions.pagination = pagination;
  defaultOptions.pagination.showSizeChanger = true;
  defaultOptions.pagination.showTotal = (t) => `共 ${t} 条`;
  return defaultOptions;
};

export const formatDate = (date: Date | undefined) => {
  return date ? dayjs(date).format('YYYY-MM-DD HH:mm') : undefined;
};

export const CreateButton: React.FC<{
  onCreate: () => void;
}> = ({ onCreate }) => {
  return (
    <Button type="primary" icon={<PlusOutlined />} onClick={onCreate}>
      新增
    </Button>
  );
};

export const UpdateIconButton: React.FC<{
  onUpdate: () => void;
}> = ({ onUpdate }) => {
  return (
    <Tooltip title="编辑">
      <EditOutlined onClick={onUpdate} />
    </Tooltip>
  );
};

export const UpdateButton: React.FC<{
  onUpdate: () => void;
}> = ({ onUpdate }) => {
  return (
    <a
      href="/"
      onClick={async (e) => {
        e.preventDefault();
        onUpdate();
      }}
    >
      编辑
    </a>
  );
};

export const DeleteIconButton: React.FC<{
  onDel: () => void;
}> = ({ onDel }) => {
  return (
    <Tooltip title="删除">
      <DeleteOutlined
        onClick={() => {
          confirm({
            title: '你想要删除此项内容吗!',
            icon: <ExclamationCircleOutlined />,
            okText: '删除',
            okType: 'danger' as LegacyButtonType,
            onOk: onDel,
            onCancel() {},
          });
        }}
      />
    </Tooltip>
  );
};
export const DeleteButton: React.FC<{
  onDel: () => void;
}> = ({ onDel }) => {
  return (
    <a
      href="/"
      onClick={async (e) => {
        e.preventDefault();
        confirm({
          title: '你想要删除此项内容吗!',
          icon: <ExclamationCircleOutlined />,
          okText: '删除',
          okType: 'danger' as LegacyButtonType,
          onOk: onDel,
          onCancel() {},
        });
      }}
    >
      删除
    </a>
  );
};

export const RefreshButton: React.FC<{
  onRefresh: () => void;
}> = ({ onRefresh }) => {
  return (
    <Tooltip title="刷新">
      <ReloadOutlined onClick={onRefresh} />
    </Tooltip>
  );
};

//跳转按钮
export const CreateNavButton: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => navigate(`${location.pathname}/new`)}
    >
      新增
    </Button>
  );
};

export const DetailIconNavButton: React.FC<{ id: number }> = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Tooltip title="查看详情">
      <EyeOutlined
        onClick={() => {
          navigate(`${location.pathname}/${id}`);
        }}
      />
    </Tooltip>
  );
};

export const DetailNavButton: React.FC<{ id: number }> = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(`${location.pathname}/${id}`);
      }}
    >
      查看详情
    </a>
  );
};

export const EditIconNavButton: React.FC<{ id: number }> = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Tooltip title="编辑">
      <EditOutlined
        onClick={() => navigate(`${location.pathname}/${id}/edit`)}
      />
    </Tooltip>
  );
};

export const EditNavButton: React.FC<{ id: number; text?: string }> = ({
  id,
  text = '编辑',
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(`${location.pathname}/${id}/edit`);
      }}
    >
      {text}
    </a>
  );
};

export const BackToListButton: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(-1);
      }}
      style={{
        textDecoration: 'none',
        color: 'rgba(0,0,0,.85)',
        fontWeight: 'bold',
      }}
    >
      <ArrowLeftOutlined style={{ padding: '0 8px 0 0' }} />
      {children}
    </a>
  );
};
