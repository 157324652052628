import { Link } from 'react-router-dom';

export const LinkToDevice: React.FC<{
  id: number;
  name: string;
  no?: string;
}> = ({ id, name, no }) => (
  <Link to={`/dm/devices/${id.toString()}`}>
    {name + (no ? `(${no})` : '')}
  </Link>
);
