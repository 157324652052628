import { useRequest } from 'ahooks';
import type { DescriptionsProps } from 'antd';
import { Badge, Card, Col, Descriptions, Empty, Row, Spin } from 'antd';
import Goback from 'common/Goback';
import dayjs from 'dayjs';
import { ProBreadcrumb } from 'pro';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMqttClient } from '../client.service';

export const ClientDetail: React.FC = () => {
  const { deviceId, clientId } = useParams();
  if (!deviceId || !clientId) {
    throw new Error('系统错误!');
  }
  const id = Number(deviceId);
  const { loading, data } = useRequest(getMqttClient, {
    defaultParams: [id, clientId],
    onSuccess: (v) => {},
  });
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: '状态',
      children: data?.connected ? (
        <Badge status="success" text="已连接" />
      ) : (
        <Badge status="default" text="离线中" />
      ),
      span: 12,
    },
    {
      key: '2',
      label: '客户端 ID',
      children: data?.clientid,
      span: 12,
    },
    {
      key: '3',
      label: '用户名',
      children: data?.username,
      span: 12,
    },
    {
      key: '4',
      label: 'IP 地址',
      children: data?.ip_address,
      span: 12,
    },
    {
      key: '5',
      label: '心跳',
      children: data?.keepalive,
      span: 12,
    },
    {
      key: '6',
      label: '清除会话',
      children: data?.clean_start?.toString(),
      span: 12,
    },
    {
      key: '7',
      label: '连接时间',
      children: dayjs(data?.connected_at).format('YYYY-MM-DD HH:mm:ss'),
      span: 12,
    },
    {
      key: '8',
      label: '断开连接时间',
      children: '',
      span: 12,
    },
  ];
  const items2: DescriptionsProps['items'] = [
    {
      key: '1',
      label: '会话过期间隔',
      children: data?.expiry_interval,
      span: 12,
    },
    {
      key: '2',
      label: '会话创建时间',
      children: dayjs(data?.created_at).format('YYYY-MM-DD HH:mm:ss'),
      span: 12,
    },
    {
      key: '3',
      label: '进程堆栈',
      children: data?.heap_size + ' bytes',
      span: 12,
    },
    {
      key: '4',
      label: '订阅数',
      children: data?.subscriptions_cnt,
      span: 12,
    },
    {
      key: '5',
      label: '消息队列',
      children: data?.mqueue_len + '/1000',
      span: 12,
    },
    {
      key: '6',
      label: '飞行窗口',
      children: 0 + '/32',
      span: 12,
    },
    {
      key: '7',
      label: 'QoS 2 报文接收队列',
      children: 0 + '/100',
      span: 12,
    },
  ];
  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={3} />
            <h2 className="content-toolbar-title">网关详情</h2>
          </div>
        </div>
      </div>

      <div>
        <Card>
          {loading ? (
            <div className="wrap-spin">
              <Spin />
            </div>
          ) : data ? (
            <Row gutter={24}>
              <Col span={12}>
                <Descriptions title="连接信息" bordered items={items} />
              </Col>
              <Col span={12}>
                <Descriptions title="会话信息" bordered items={items2} />
              </Col>
            </Row>
          ) : (
            <Empty />
          )}
        </Card>
      </div>
    </div>
  );
};

export const ClientDetailBtn: React.FC<{
  deviceId: number;
  clientId: string;
}> = ({ deviceId, clientId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        navigate(`${location.pathname}/${deviceId}/clients/${clientId}`);
      }}
    >
      网关详情
    </a>
  );
};
