import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  AlarmConfig,
  CreateAlarmConfigDto,
  UpdateAlarmConfigDto,
} from './alarm-config.interface';
import { apiPrefix as api } from 'common/url.help';

export const getAlarmConfigs = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/alarm-configs?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<AlarmConfig>>(url));
  return { total: data.total, list: data.items };
};

export const getAlarmConfigsSelected = async () => {
  const url = api(`/alarm-configs/selected`);
  return getData(axios.get<AlarmConfig[]>(url));
};

export const getAlarmConfig = async (id: number) => {
  const url = api(`/alarm-configs/${id}`);
  return getData(axios.get<AlarmConfig>(url));
};

export const deleteAlarmConfig = async (id: number) => {
  const url = api(`/alarm-configs/${id}`);
  return getData(axios.delete<void>(url));
};

export const createAlarmConfig = async (value: CreateAlarmConfigDto) => {
  const url = api(`/alarm-configs`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateAlarmConfig = async (
  id: number,
  value: UpdateAlarmConfigDto
) => {
  const url = api(`/alarm-configs/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
