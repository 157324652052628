/* eslint-disable import/no-extraneous-dependencies */
import { Modal } from 'antd';
import { getYsConfig } from 'video/ysvideo/ysconfig/ysconfig.service';
import { getYsvideo } from '../ysvideo.service';
import './index.css';

const YsvideoModal: React.FC<{
  state: [
    {
      open: boolean;
      id: number;
    },
    React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        id: number;
      }>
    >
  ];
}> = ({ state: [{ open, id }, setOpen] }) => {
  const onLoad = async () => {
    const [{ deviceId, channelId }, [accessToken, templateId]] =
      await Promise.all([getYsvideo(id), getYsConfig()]);

    const iframeWrap = document.querySelector(
      '#ys-video-container-wrap'
    ) as HTMLIFrameElement;
    const parentData = {
      deviceId,
      channelId,
      accessToken,
      templateId,
    };

    iframeWrap.contentWindow?.postMessage(parentData, '*');
  };

  const onCancel = () => {
    setOpen((v) => ({ ...v, open: false }));
  };

  return (
    <Modal
      title="播放视频"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
      className="video-modal"
    >
      <iframe
        id="ys-video-container-wrap"
        title="ys-video"
        src="/ys-video.html"
        width={752}
        height={424}
        onLoad={onLoad}
      ></iframe>
    </Modal>
  );
};

export default YsvideoModal;
