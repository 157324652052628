import { Tabs } from 'antd';
import { ProBreadcrumb } from 'pro';
import AlarmList3 from './ListHistory';
import AlarmList2 from './ListToday';

const AlarmContainer: React.FC = () => {
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div
          className="content-toolbar-container"
          style={{ marginBottom: '8px' }}
        >
          <h2 className="content-toolbar-title">报警预览</h2>
        </div>
      </div>
      <div className="content-tabs-wrap">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: `当天`,
              children: <AlarmList2 />,
            },
            {
              key: '2',
              label: `历史报警`,
              children: <AlarmList3 />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default AlarmContainer;
