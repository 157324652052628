import { useAntdTable } from 'ahooks';
import { Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CreateNavButton,
  DeleteButton,
  DetailNavButton,
  EditNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { Solution } from '../solution.interface';
import { deleteSolution, getSolutions } from '../solution.service';
import { Link } from 'react-router-dom';
import { ProBreadcrumb } from 'pro';

const SolutionList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getSolutions, {
    //refreshOnWindowFocus: true,
  });
  const columns: ColumnsType<Solution> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '方案名称',
      render: (_, { id, name }) => <Link to={id.toString()}>{name}</Link>,
    },
    {
      title: '故障类型',
      render: (_, { categories }) =>
        categories &&
        categories.map((cate) => (
          <Tag color="blue" key={cate.id}>
            {cate.name}
          </Tag>
        )),
    },
    {
      title: '问题描述',
      dataIndex: 'problem',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          <EditNavButton id={id} />
          <DeleteButton onDel={() => deleteSolution(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">解决方案</h2>
          <Space>
            <CreateNavButton />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default SolutionList;
