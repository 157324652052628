import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import { apiPrefix as api } from 'common/url.help';
import {
  WorkorderCategory,
  CreateWorkorderCategoryDto,
  UpdateWorkorderCategoryDto,
} from './workorder-category.interface';

export const getWorkorderCategories = async ({
  current,
  pageSize,
}: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api(
    '/workorder/workorder-categories?' + qs.stringify(requestParms)
  );
  const data = await getData(
    axios.get<PaginationResult<WorkorderCategory>>(url)
  );
  return { total: data.total, list: data.items };
};

export const getWorkorderCategoriesSelected = async () => {
  const url = api('/workorder/workorder-categories/selected');
  return getData(axios.get<WorkorderCategory[]>(url));
};

export const getWorkorderCategory = async (id: number) => {
  const url = api(`/workorder/workorder-categories/${id}`);
  return getData(axios.get<WorkorderCategory>(url));
};

export const deleteWorkorderCategory = async (id: number) => {
  const url = api(`/workorder/workorder-categories/${id}`);
  return getData(axios.delete<void>(url));
};

export const createWorkorderCategory = async (
  value: CreateWorkorderCategoryDto
) => {
  const url = api(`/workorder/workorder-categories`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateWorkorderCategory = async (
  id: number,
  value: UpdateWorkorderCategoryDto
) => {
  const url = api(`/workorder/workorder-categories/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
