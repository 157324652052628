import { useRequest } from 'ahooks';
import { Button, Card, Col, Form, Input, Row, Select, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import type { UploadFile } from 'antd/es/upload/interface';
import Goback from 'common/Goback';
import { UploadImagesInput } from 'files/UploadImagesInput';
import { convertFileList, normFile } from 'files/files.service';
import { ProBreadcrumb } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateSolutionDto } from '../solution.interface';
import {
  getFailureCategoriesSelected,
  getSolution,
  updateSolution,
} from '../solution.service';

const SolutionUpdate: React.FC = () => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateSolution, { manual: true });
  const { data } = useRequest(getFailureCategoriesSelected);
  const navigate = useNavigate();

  const { solutionId } = useParams();
  if (!solutionId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(solutionId);
  useRequest(getSolution, {
    defaultParams: [id],
    onSuccess: (v) => {
      file1State[1](convertFileList(v.problemAttachments));
      file2State[1](convertFileList(v.noteAttachments));
      form.setFieldsValue(v);
      v.categories &&
        form.setFieldsValue({
          categoryIds: v.categories.map((r) => r.id),
        });
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
  };
  const onFinish = async (value: UpdateSolutionDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      onCancel();
      form.resetFields();
      navigate(-1);
      message.success('编辑成功!');
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  const file1State = useState<UploadFile[]>();
  const file2State = useState<UploadFile[]>();

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">编辑方案</h2>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: '880px' }}>
        <Card>
          <Form
            layout="vertical"
            form={form}
            preserve={false}
            onFinish={onFinish}
          >
            <FormAlert {...alert} />
            <Form.Item
              name="name"
              label="方案名称"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="categoryIds" label="故障类型">
              <Select
                mode="multiple"
                placeholder="选择故障类型"
                allowClear
                options={
                  data && data.map((m) => ({ value: m.id, label: m.name }))
                }
              />
            </Form.Item>
            <Form.Item
              name="problem"
              label="问题描述"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="problemAttachments"
              label="附件"
              getValueFromEvent={normFile}
            >
              <UploadImagesInput fileState={file1State} />
            </Form.Item>
            <Form.Item
              name="note"
              label="解决方案"
              rules={[{ required: true }]}
            >
              <TextArea rows={8} />
            </Form.Item>
            <Form.Item
              name="noteAttachments"
              label="附件"
              getValueFromEvent={normFile}
            >
              <UploadImagesInput fileState={file2State} />
            </Form.Item>
            <Row>
              <Col
                className="gutter-row"
                span={24}
                style={{ textAlign: 'right' }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  保存
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SolutionUpdate;
