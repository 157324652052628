import { Space } from 'antd';
import { CreateButton, RefreshButton } from 'pro/table.pro';
import { FC, SetStateAction } from 'react';

export const ProCreateButtons: FC<{
  setCreateState: (value: SetStateAction<boolean>) => void;
  refresh: () => void;
}> = ({ setCreateState, refresh }) => {
  return (
    <Space>
      <CreateButton onCreate={() => setCreateState(true)} />
      <RefreshButton onRefresh={refresh} />
    </Space>
  );
};
