import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Device,
  CreateDeviceDto,
  UpdateDeviceDto,
  CreateMaintainItemDto,
  MaintainItem,
  UpdateMaintainItemDto,
} from './devices.interface';
import { apiPrefix as api } from 'common/url.help';

export const getDevices = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/devices?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Device>>(url));
  return { total: data.total, list: data.items };
};

export const getDevice = async (id: number) => {
  const url = api(`/devices/${id}`);
  return getData(axios.get<Device>(url));
};

export const getDevicesSelected = async () => {
  const url = api('/devices/selected');
  return getData(axios.get<Device[]>(url));
};

export const deleteDevice = async (id: number) => {
  const url = api(`/devices/${id}`);
  return getData(axios.delete<void>(url));
};

export const createDevice = async (value: CreateDeviceDto) => {
  const url = api(`/devices`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateDevice = async (id: number, value: UpdateDeviceDto) => {
  const url = api(`/devices/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const getMaintainItems = async ({
  current,
  pageSize,
  deviceId,
}: RequestParms & { deviceId: number }) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api(
    `/devices/${deviceId}/maintain-items?` + qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<MaintainItem>>(url));
  return { total: data.total, list: data.items };
};

export const getMaintainItem = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/maintain-items/${id}`);
  return getData(axios.get<MaintainItem>(url));
};

export const deleteMaintainItem = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/maintain-items/${id}`);
  return getData(axios.delete<void>(url));
};

export const createMaintainItem = async (
  deviceId: number,
  value: CreateMaintainItemDto
) => {
  const url = api(`/devices/${deviceId}/maintain-items`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateMaintainItem = async (
  deviceId: number,
  id: number,
  value: UpdateMaintainItemDto
) => {
  const url = api(`/devices/${deviceId}/maintain-items/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
