import { Form, Input } from 'antd';

const FormItems: React.FC = () => {
  return (
    <>
      <Form.Item name="name" label="名称" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </>
  );
};

export default FormItems;
