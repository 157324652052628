import { Tabs } from 'antd';
import { ProBreadcrumb } from 'pro';
import YsvideoList from '../ysvideo/components/List';
import YsconfigList from '../ysconfig/components/List';

const YsvideoContainer: React.FC = () => {
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div
          className="content-toolbar-container"
          style={{ marginBottom: '8px' }}
        >
          <h2 className="content-toolbar-title">萤石云配置</h2>
        </div>
      </div>
      <div className="content-tabs-wrap">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: `摄像头配置`,
              children: <YsvideoList />,
            },
            {
              key: '2',
              label: `初始参数配置`,
              children: <YsconfigList />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default YsvideoContainer;
