import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { Gbvideo } from '../gbvideo.interface';
import { deleteGbvideo, getAllGbvideo } from '../gbvideo.service';
import CreateGbvideoModal from './Create';
import UpdateGbvideoModal from './Update';
import GbvideoModal from './Video';

const GbvideoList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getAllGbvideo, {
    //refreshOnWindowFocus: true,
  });
  const createState = useState(false);
  const updateState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });
  const videoState = useState<{ open: boolean; id: number }>({
    open: false,
    id: 0,
  });

  const columns: ColumnsType<Gbvideo> = [
    {
      title: '所属公司',
      dataIndex: ['company', 'name'],
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '设备序列号',
      dataIndex: 'deviceId',
    },
    {
      title: '通道编号',
      dataIndex: 'channelCode',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              videoState[1]({ open: true, id });
            }}
          >
            播放
          </a>
          <UpdateButton onUpdate={() => updateState[1]({ open: true, id })} />
          <DeleteButton onDel={() => deleteGbvideo(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">国标接入配置</h2>
          <Space>
            <CreateButton onCreate={() => createState[1](true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <CreateGbvideoModal createState={createState} refresh={refresh} />
      {updateState[0].open && (
        <UpdateGbvideoModal updateState={updateState} refresh={refresh} />
      )}
      {videoState[0].open && <GbvideoModal state={videoState} />}
    </>
  );
};

export default GbvideoList;
