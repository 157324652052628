import { useRequest } from 'ahooks';
import { Form, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';

interface PropsType<T> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  service: (value: T) => Promise<unknown>;
  children: React.ReactNode;
}

function CreateModal<T>({
  open,
  setOpen,
  refresh,
  service,
  children,
}: React.PropsWithChildren<PropsType<T>>) {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(service, { manual: true });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: T) => {
    try {
      setAlert({ display: false });

      await runAsync(value);
      message.success('新增成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="新增纪录"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <FormAlert {...alert} />
        {children}
      </Form>
    </Modal>
  );
}
export default CreateModal;
