import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  Company,
  CreateCompanyDto,
  UpdateCompanyDto,
} from './company.interface';
import { CreateUserDto, User } from 'auth/auth.interface';

const apiCompanies = (p = '') => {
  return api('/companies', p);
};
export const getCompanies = async ({
  current,
  pageSize,
}: {
  current: number;
  pageSize: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = apiCompanies('?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Company>>(url));
  return { total: data.total, list: data.items };
};

export const getCompany = async (id: number) => {
  return getData(axios.get<Company>(apiCompanies('/' + id)));
};

export const deleteCompany = async (id: number) => {
  return getData(axios.delete<void>(apiCompanies('/' + id)));
};

export const createCompany = async (value: CreateCompanyDto) => {
  return getData(
    axios.post(apiCompanies(), value, { handlerErrorManually: true })
  );
};

export const updateCompany = async (id: number, value: UpdateCompanyDto) => {
  return getData(
    axios.patch(apiCompanies('/' + id), value, { handlerErrorManually: true })
  );
};

export const getCompaniesUsers = async ({
  companyId,
  current,
  pageSize,
}: {
  companyId: number;
  current: number;
  pageSize: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = apiCompanies(`/${companyId}/users?` + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<User>>(url));
  return { total: data.total, list: data.items };
};

export const createCompaniesUser = async (
  companyId: number,
  value: CreateUserDto
) => {
  return getData(
    axios.post(apiCompanies(`/${companyId}/users`), value, {
      handlerErrorManually: true,
    })
  );
};
