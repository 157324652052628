import { UploadFile, UploadProps } from 'antd';
import { getAuthorization } from 'auth/AuthProvider';
import axios, { getData } from 'common/axios.pro';
import { FileMetadataDto } from 'files/files.interface';
import { SetStateAction } from 'react';
import type { RcFile } from 'antd/es/upload/interface';
import { filePrefix } from 'common/url.help';

export const FILE_UPLOAD_PATH = filePrefix('/uploads');

export const deleteFile = async (url: string) => {
  return getData(axios.delete<void>(url));
};
// 远程获取的 FileMetadataDto[] 传递到 upload 组件中
// 需要包装成 UploadFile[]
export const convertFileList = (attachments?: FileMetadataDto[]) => {
  const files =
    attachments &&
    attachments.map(
      (f) =>
        ({
          uid: f.id.toString(),
          name: f.originalname,
          url: f.downloadUrl,
          status: 'done',
          response: f,
        } as UploadFile)
    );
  return files;
};

export const convertFile = (avatar?: FileMetadataDto) => {
  if (avatar) {
    const file = {
      uid: avatar.id.toString(),
      name: avatar.originalname,
      url: avatar.downloadUrl,
      status: 'done',
      response: avatar,
    } as UploadFile<FileMetadataDto>;
    return file;
  }
};

export const uploadProps = (
  setFileList: (
    value: SetStateAction<UploadFile<unknown>[] | undefined>
  ) => void
) => {
  const props: UploadProps = {
    name: 'file',
    action: FILE_UPLOAD_PATH,
    headers: {
      Authorization: getAuthorization(),
    },
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.downloadUrl;
        }
        return file;
      });

      setFileList(newFileList);
    },
    onRemove(file) {
      deleteFile(file.response.deleteUrl);
    },
  };

  return props;
};

export const uploadImageProps = (
  setImage: (value: SetStateAction<UploadFile<unknown> | undefined>) => void
) => {
  const props: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    action: FILE_UPLOAD_PATH,
    headers: {
      Authorization: getAuthorization(),
    },
    onChange(info) {
      console.log('onChange');
      console.log(info);
      if (info.file.response) {
        info.file.url = info.file.response.downloadUrl;
      }
      setImage(info.file);
    },
    onRemove(file) {
      deleteFile(file.response.deleteUrl);
    },
    onPreview: async (file: UploadFile) => {
      let src = file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
  };

  return props;
};
// 把 upload 组件的数据传递到外层 form item 中
// 需要传递的数据是 FileMetadataDto[]
export const normFile = (e: { fileList: UploadFile<FileMetadataDto>[] }) => {
  if (Array.isArray(e)) {
    // UploadInput 组件中 e: FileMetadataDto[]
    return e;
  }
  return e?.fileList.map((f) => f?.response);
};

export const normImage = (e: { fotos: UploadFile<FileMetadataDto> }) => {
  if (e?.fotos?.status === 'removed') {
    return null;
  }
  return e.fotos.response;
};
