import { PaperClipOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import {
  deleteMaintainItem,
  getMaintainItems,
} from 'devices/device/devices.service';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { MaintainItem } from '../device/devices.interface';
import CreateMaintainItemModal from './Create';
import UpdateMaintainItemModal from './Update';

const MaintainItemFc: React.FC<{ deviceId: number }> = ({ deviceId }) => {
  const { tableProps, refresh } = useAntdTable(getMaintainItems, {
    //refreshOnWindowFocus: true,
    defaultParams: [{ current: 1, pageSize: 10, deviceId }],
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );

  const columns: ColumnsType<MaintainItem> = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: '维修养护内容',
      key: 'content',
      dataIndex: 'content',
    },
    {
      title: '类型',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: '实施时间',
      key: 'date',
      dataIndex: 'date',
      render: (_, { date }) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: '实施单位',
      key: 'implementer',
      dataIndex: 'implementer',
    },
    {
      title: '实施前照片',
      key: 'beforeAttachments',
      dataIndex: 'beforeAttachments',
      render: (_, { beforeAttachments }) => {
        if (beforeAttachments) {
          return beforeAttachments.map((f) => (
            <span key={f.id}>
              <a href={f.downloadUrl}>
                <PaperClipOutlined />
                {f.originalname}
              </a>
              <br></br>
            </span>
          ));
        }
      },
    },
    {
      title: '实施后照片',
      key: 'afterAttachments',
      dataIndex: 'afterAttachments',
      render: (_, { afterAttachments }) => {
        if (afterAttachments) {
          return afterAttachments.map((f) => (
            <span key={f.id}>
              <a href={f.downloadUrl}>
                <PaperClipOutlined />
                {f.originalname}
              </a>
              <br></br>
            </span>
          ));
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
          <DeleteButton
            onDel={() => deleteMaintainItem(deviceId, id).then(refresh)}
          />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar" style={{ marginTop: '16px' }}>
        <div className="content-toolbar-container">
          <h3 className="content-toolbar-title">设备维修养护</h3>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {createState ? (
        <CreateMaintainItemModal
          deviceId={deviceId}
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
        />
      ) : (
        <></>
      )}
      {updateState.open ? (
        <UpdateMaintainItemModal
          deviceId={deviceId}
          open={updateState.open}
          setOpen={setUpdateState}
          refresh={refresh}
          id={updateState.id}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MaintainItemFc;
