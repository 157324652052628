import { Alert } from 'antd';

export interface FormAlertProps {
  display: boolean;
  msg?: string;
}

const FormAlert: React.FC<FormAlertProps> = ({ display, msg }) => {
  return (
    <>
      {display && (
        <Alert
          className="form-alert"
          message={msg}
          type="error"
          showIcon
          style={{ margin: '0 0 24px 0' }}
        />
      )}
    </>
  );
};

export default FormAlert;

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}
