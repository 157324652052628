import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import FormItems from './FormItems';
import {
  Company,
  CreateCompanyDto,
  UpdateCompanyDto,
} from './company.interface';
import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompany,
  updateCompany,
} from './company.service';
import { Link } from 'react-router-dom';
import { ProBreadcrumb, ProModal } from 'pro';

const CompanyFc: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getCompanies, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );

  const columns: ColumnsType<Company> = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: '名称',
      key: 'name',
      dataIndex: 'name',
      render: (_, { id, name }) => <Link to={id.toString()}>{name}</Link>,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton onUpdate={() => setUpdateState({ open: true, id })} />
          <DeleteButton onDel={() => deleteCompany(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">公司管理</h2>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <ProModal<CreateCompanyDto, UpdateCompanyDto, Company>
        createState={createState}
        updateState={updateState}
        setCreateState={setCreateState}
        setUpdateState={setUpdateState}
        create={createCompany}
        update={updateCompany}
        getOne={getCompany}
        refresh={refresh}
      >
        <FormItems />
      </ProModal>
    </>
  );
};

export default CompanyFc;
