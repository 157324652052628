import { useRequest } from 'ahooks';
import { Button, Form, Input, message } from 'antd';
import { useAuth } from 'auth/AuthProvider';
import LoginFormAlert from 'auth/login/LoginFormAlert';
import { useLocation, useNavigate } from 'react-router-dom';

const NormalLoginForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { loading, error, run } = useRequest(auth.login, {
    manual: true,
    onSuccess: (result, params) => {
      const state = location.state as { from: Location };
      const from = state?.from?.pathname ?? '/';
      navigate(from, { replace: true });
      message.success('登录成功');
    },
    onError: () => {},
  });

  return (
    <Form
      name="normal_login"
      className="login-form"
      size={'large'}
      onFinish={run}
    >
      <LoginFormAlert error={error} />
      <Form.Item
        name="username"
        rules={[{ required: true, message: ' 请输入用户名!' }]}
      >
        <Input placeholder="请输入用户名" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: '请输入密码!' }]}
      >
        <Input.Password type="password" placeholder="请输入密码" />
      </Form.Item>
      <Form.Item style={{ margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NormalLoginForm;
