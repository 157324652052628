import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import { CreateTagDto, SetValueDto, Tag, UpdateTagDto } from './tag.interface';

export const getTags = async ({
  deviceId,
  current,
  pageSize,
}: {
  deviceId: number;
  current: number;
  pageSize: number;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = api(`/devices/${deviceId}/tags?` + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Tag>>(url));
  return { total: data.total, list: data.items };
};

export const getTagsSelected = async (deviceId: number) => {
  const url = api(`/devices/${deviceId}/tags/selected`);
  return getData(axios.get<Tag[]>(url));
};

export const getTag = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/tags/${id}`);
  return getData(axios.get<Tag>(url));
};

export const deleteTag = async (deviceId: number, id: number) => {
  const url = api(`/devices/${deviceId}/tags/${id}`);
  return getData(axios.delete<void>(url));
};

export const createTag = async (deviceId: number, value: CreateTagDto) => {
  const url = api(`/devices/${deviceId}/tags`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateTag = async (
  deviceId: number,
  id: number,
  value: UpdateTagDto
) => {
  const url = api(`/devices/${deviceId}/tags/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const setVal = async (
  deviceId: number,
  id: number,
  dto: SetValueDto
) => {
  const url = api(`/devices/${deviceId}/tags/${id}/setval`);
  return getData(axios.patch(url, dto, { handlerErrorManually: true }));
};
