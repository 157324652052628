import { useAntdTable } from 'ahooks';
import { Badge, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dayjsfmt } from 'common/dayjsfmt';
import { RefreshButton, extraProps } from 'pro/table.pro';
import { MessageLog } from '../msglogs.interface';
import { getMessageLogs } from '../msglogs.service';
import { ProBreadcrumb } from 'pro';

const MessageLogList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getMessageLogs, {
    //refreshOnWindowFocus: true,
    defaultPageSize: 10,
  });

  const columns: ColumnsType<MessageLog> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '状态',
      render: (_, { status }) =>
        status === '已发送' && <Badge status="success" text="已发送" />,
    },
    {
      title: '时间',
      render: (_, { date }) => dayjsfmt(date),
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
    },
    {
      title: '通知内容',
      dataIndex: 'message',
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">报警通知</h2>
          <Space>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default MessageLogList;
