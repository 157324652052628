import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import { changePassword } from 'auth/auth.service';
import { useAuth } from 'auth/AuthProvider';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';

const ChangePasswordModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const auth = useAuth();
  const [changePasswordForm] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(changePassword, { manual: true });

  const closeModal = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      setAlert({ display: false });

      const user = await auth.getUser();
      await runAsync(user.id, value);
      message.success('修改成功!');
      closeModal();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };
  return (
    <Modal
      title="修改密码"
      open={open}
      onOk={changePasswordForm.submit}
      confirmLoading={loading}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <Form
        form={changePasswordForm}
        name="change-password-form"
        className="change-password-form"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        preserve={false}
      >
        <FormAlert {...alert} />
        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[{ required: true, message: '请输入您的密码!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPassword"
          rules={[{ required: true, message: '请输入您的密码!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="再次输入新密码"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: '请输入您的密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次输入的密码不一致!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ChangePasswordModal;
