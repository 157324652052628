import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useAntdTable, useRequest } from 'ahooks';
import { AntdTableResult } from 'ahooks/lib/useAntdTable/types';
import { Button, Card, Modal, Space, Spin, Table, Tag, Tree } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import { ColumnsType } from 'antd/es/table';
import { DataNode, TreeProps } from 'antd/es/tree';
import { User } from 'auth/auth.interface';
import { deleteUser } from 'auth/auth.service';
import ResetPasswordModal from 'auth/user/ResetPassword';
import {
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { Dispatch, useEffect, useState } from 'react';
import CreateOrgModal from './Orgs/Create';
import UpdateOrgModal from './Orgs/Update';
import CreateUserModal from './Users/Create';
import UpdateUserModal from './Users/Update';
import { Org } from './company.interface';
import {
  deleteOrg,
  getOrgs as getAll,
  getInCompaniesUsers1,
  getInCompaniesUsers2,
} from './in-company.service';
import './index.css';
const { confirm } = Modal;
type SelectedStatusType = {
  selected: boolean;
  selectedNodes: DataNode[];
};
type R1 = AntdTableResult<
  {
    total: number;
    list: User[];
  },
  [
    {
      companyId: number;
      current: number;
      pageSize: number;
    }
  ]
>;

type R2 = AntdTableResult<
  {
    total: number;
    list: User[];
  },
  [
    {
      companyId: number;
      orgId: number;
      current: number;
      pageSize: number;
    }
  ]
>;

const OrgUsers: React.FC<{ companyId: number }> = ({ companyId }) => {
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatusType>({
    selected: false,
    selectedNodes: [],
  });
  const r1 = useAntdTable(getInCompaniesUsers1, {
    manual: true,
    defaultParams: [{ companyId, current: 1, pageSize: 10 }],
    //refreshOnWindowFocus: true,
  });
  const r2 = useAntdTable(getInCompaniesUsers2, {
    manual: true,
    defaultParams: [{ companyId, orgId: -1, current: 1, pageSize: 10 }],
    //refreshOnWindowFocus: true,
  });

  return (
    <div className="in-company-warp">
      <OrgFc
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        companyId={companyId}
        r1={r1}
        r2={r2}
      />
      <CompanyUserFc
        companyId={companyId}
        selectedStatus={selectedStatus}
        r1={r1}
        r2={r2}
      />
    </div>
  );
};

export default OrgUsers;

export const OrgFc: React.FC<{
  selectedStatus: SelectedStatusType;
  setSelectedStatus: Dispatch<React.SetStateAction<SelectedStatusType>>;
  companyId: number;
  r1: R1;
  r2: R2;
}> = ({ selectedStatus, setSelectedStatus, companyId, r1, r2 }) => {
  const [data, setData] = useState<DataNode[]>();
  // const [selectedStatus, setSelectedStatus] = useState<SelectedStatusType>({
  //   selected: false,
  //   selectedNodes: [],
  // });

  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState<{ open: boolean; id: number }>(
    {
      open: false,
      id: -1,
    }
  );
  const [orgId, setOrgId] = useState(0);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
  const { loading, runAsync } = useRequest(getAll, { manual: true });

  const refresh = async () => {
    const org = await runAsync(companyId);
    if (org) {
      const eks = [org.id];

      const doLoop = (
        children: Org[],
        extractChildren: (o: Org) => Org[] | undefined
      ): DataNode[] => {
        return children.map((c) => {
          eks.push(c.id);
          const o: DataNode = {
            key: c.id,
            title: c.name,
            children: doLoop(extractChildren(c) || [], extractChildren),
          };
          return o;
        });
      };
      const extractChildren = (o: Org) => o.children;
      const n: DataNode = {
        key: org.id,
        title: org.name,
        icon: <HomeOutlined />,
        children: doLoop(org.children, extractChildren),
      };
      setData([n]);
      setExpandedKeys(eks);
    }
  };

  useEffect(() => {
    refresh();
  }, [companyId]);

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    const key = selectedKeys.length > 0 ? +selectedKeys[0].toString() : -1;
    setSelectedStatus({
      selected: info.selected,
      selectedNodes: info.selectedNodes,
    });
    setOrgId(key);
    if (info.selected) {
      r2.runAsync({
        companyId,
        orgId: key,
        current: 1,
        pageSize: 10,
      });
    } else {
      r1.runAsync({ companyId, current: 1, pageSize: 10 });
    }
  };

  return (
    <div className="in-company-orgs-warp">
      <div className="content-toolbar" style={{ height: '48px' }}>
        <div className="content-toolbar-container">
          <h3 className="content-toolbar-title">组织管理</h3>
          <div>
            <Button
              type="text"
              size="small"
              disabled={!selectedStatus.selected}
              icon={<PlusOutlined />}
              onClick={() => setCreateState(true)}
            />
            <Button
              type="text"
              size="small"
              disabled={!selectedStatus.selected}
              icon={<EditOutlined />}
              onClick={() =>
                setUpdateState((s) => {
                  return { ...s, open: true };
                })
              }
            />
            <Button
              type="text"
              size="small"
              disabled={!selectedStatus.selected}
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: '你想要删除此项内容吗!',
                  icon: <ExclamationCircleOutlined />,
                  okText: '删除',
                  okType: 'danger' as LegacyButtonType,
                  onOk: () => deleteOrg(companyId, orgId).then(refresh),
                  onCancel() {},
                });
              }}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <Card className="in-company-orgs-tree-spin">
          <Spin />
        </Card>
      ) : (
        <Card>
          <Tree
            blockNode={true}
            showIcon={true}
            treeData={data}
            onSelect={onSelect}
            expandedKeys={expandedKeys}
          />
        </Card>
      )}

      {createState && (
        <CreateOrgModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
          parentId={orgId}
          companyId={companyId}
        />
      )}
      {updateState.open && (
        <UpdateOrgModal
          open={updateState.open}
          setOpen={setUpdateState}
          refresh={refresh}
          id={orgId}
          companyId={companyId}
        />
      )}
    </div>
  );
};

export const CompanyUserFc: React.FC<{
  selectedStatus: SelectedStatusType;
  companyId: number;
  r1: R1;
  r2: R2;
}> = ({ selectedStatus, companyId, r1, r2 }) => {
  const refresh = selectedStatus.selected ? r2.refresh : r1.refresh;
  const tableProps = selectedStatus.selected ? r2.tableProps : r1.tableProps;
  const [openReset, setOpenReset] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    r1.runAsync({ companyId, current: 1, pageSize: 10 });
  }, [companyId]);
  const columns: ColumnsType<User> = [
    {
      title: '用户名',
      dataIndex: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '电话',
      dataIndex: 'phone',
    },
    {
      title: '部门',
      dataIndex: ['org', 'name'],
    },
    {
      title: '角色',
      key: 'roles',
      dataIndex: 'roles',
      render: (_, { roles }) => (
        <>
          {roles
            ? roles.map((role) => {
                return <Tag key={role.id}>{role.name}</Tag>;
              })
            : '-'}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton
            onUpdate={() => {
              setUserId(id);
              setUpdateState(true);
            }}
          />
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              setUserId(id);
              setOpenReset(true);
            }}
          >
            重置密码
          </a>
          <DeleteButton onDel={() => deleteUser(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <div className="in-company-users-warp">
      <div className="content-toolbar">
        <div className="content-toolbar-container">
          <h3 className="content-toolbar-title">
            {selectedStatus.selected
              ? selectedStatus.selectedNodes[0]?.title?.toString()
              : '全部'}
            用户
          </h3>
          <Space>
            <Button
              disabled={!selectedStatus.selected}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateState(true)}
            >
              新增
            </Button>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {openReset && (
        <ResetPasswordModal
          open={openReset}
          setOpen={setOpenReset}
          id={userId}
        />
      )}
      {createState && (
        <CreateUserModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
          companyId={companyId}
          orgId={+selectedStatus?.selectedNodes[0]?.key.toString()}
        />
      )}
      {updateState && (
        <UpdateUserModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={userId}
        />
      )}
    </div>
  );
};
