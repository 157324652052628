import { Upload, UploadFile } from 'antd';
// import ImgCrop from 'antd-img-crop';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { getAuthorization } from 'auth/AuthProvider';
import { Dispatch, SetStateAction } from 'react';
import { FileMetadataDto } from './files.interface';
import { deleteFile, FILE_UPLOAD_PATH } from './files.service';

export const CroppedInput: React.FC<{
  image?: UploadFile<FileMetadataDto>;
  setImage: Dispatch<SetStateAction<UploadFile<FileMetadataDto> | undefined>>;
  value?: {} | undefined;
  onChange?: (e: { fotos: UploadFile<FileMetadataDto> }) => void | undefined;
}> = ({ image: fotos, setImage: setfotos, value, onChange }) => {
  //const [fotos, setfotos] = useState<UploadFile>();

  const triggerChange = (changedValue: {
    fotos: UploadFile<FileMetadataDto>;
  }) => {
    onChange?.({
      //fotos1,
      ...value,
      ...changedValue,
    });
  };

  const onChange2 = ({
    file,
    fileList,
  }: UploadChangeParam<UploadFile<FileMetadataDto>>) => {
    if (fileList.length > 0) {
      setfotos(file);
    } else {
      setfotos(undefined);
    }

    triggerChange({
      fotos: file,
    });
  };
  const props: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    action: FILE_UPLOAD_PATH,
    headers: {
      Authorization: getAuthorization(),
    },

    onRemove(file) {
      setfotos(file);
      deleteFile(file.response.deleteUrl);
    },
    onPreview: async (file: UploadFile) => {
      let src = file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    },
  };
  return (
    <Upload
      {...props}
      fileList={fotos ? [fotos] : undefined}
      maxCount={1}
      onChange={(e) => onChange2(e)}
    >
      {fotos ? null : '上传图片'}
    </Upload>
  );
};
