import { Tabs } from 'antd';
import Goback from 'common/Goback';
import CompanyUser from './CompanyUser/CompanyUser';
import { useParams } from 'react-router-dom';
import { ProBreadcrumb } from 'pro';

const CompanyDetailFc: React.FC = () => {
  const { companyId } = useParams();
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div
          className="content-toolbar-container"
          style={{ paddingBottom: '8px' }}
        >
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">明细配置</h2>
          </div>
        </div>
      </div>
      <div className="content-tabs-wrap">
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: `用户管理`,
              children: <CompanyUser companyId={Number(companyId)} />,
            },
            {
              key: '2',
              label: `设备管理`,
              children: '',
            },
          ]}
        />
      </div>
    </>
  );
};

export default CompanyDetailFc;
