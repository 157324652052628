import { ExclamationCircleFilled } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Badge, Modal, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { dayjsfmt1 } from 'common/dayjsfmt';
import { LinkToDevice } from 'devices/device/components/LinkToDevice';
import { ProBreadcrumb } from 'pro';
import {
  CreateNavButton,
  DeleteButton,
  DetailNavButton,
  EditNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Workorder } from '../workorder.interface';
import {
  closeWorkorder,
  deleteWorkorder,
  getWorkorders,
} from '../workorder.service';
import UpdateMaintain from './UpdateMaintain';
const { confirm } = Modal;

const WorkorderList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getWorkorders, {
    //refreshOnWindowFocus: true,
  });
  const [updateMaintainState, setUpdateMaintainState] = useState(false);
  const [workorderId, setWorkorderId] = useState(0);

  const columns: ColumnsType<Workorder> = [
    {
      title: '编号',
      render: (_, { id, no }) => <Link to={id.toString()}>{no}</Link>,
    },
    {
      title: '来源',
      dataIndex: 'type',
    },
    {
      title: '状态',
      render: (_, { status }) =>
        (status === '受理中' && <Badge status="warning" text="受理中" />) ||
        (status === '处理中' && <Badge status="processing" text="处理中" />) ||
        (status === '已完成' && <Badge status="success" text="已完成" />) ||
        (status === '已关闭' && <Badge status="default" text="已关闭" />),
    },
    {
      title: '设备',
      render: (_, { device }) => <LinkToDevice {...device} />,
    },
    {
      title: '类型',
      render: (_, { categories }) =>
        categories &&
        categories.map((cate) => (
          <Tag color="blue" key={cate.id}>
            {cate.name}
          </Tag>
        )),
    },
    {
      title: '开始时间',
      render: (_, { startAt }) => dayjsfmt1(startAt),
    },
    {
      title: '结束时间',
      render: (_, { serviceEndAt }) => dayjsfmt1(serviceEndAt),
    },
    {
      title: '联系人',
      render: (_, { client }) =>
        client.phone ? (
          <Tooltip title={'电话：' + client.phone}>{client.name}</Tooltip>
        ) : (
          client.name
        ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              setWorkorderId(id);
              setUpdateMaintainState(true);
            }}
          >
            处理工单
          </a>
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              confirm({
                title: '你想要关闭此项工单吗!',
                icon: <ExclamationCircleFilled />,
                okText: '关闭',
                onOk: () => closeWorkorder(id).then(refresh),
                onCancel() {},
              });
            }}
          >
            关闭
          </a>
          <EditNavButton id={id} />
          <DeleteButton onDel={() => deleteWorkorder(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">工单管理</h2>
          <Space>
            <CreateNavButton />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {updateMaintainState && (
        <UpdateMaintain
          open={updateMaintainState}
          setOpen={setUpdateMaintainState}
          refresh={refresh}
          id={workorderId}
        />
      )}
    </>
  );
};

export default WorkorderList;
