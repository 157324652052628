import {
  AlertOutlined,
  CarryOutOutlined,
  RobotOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

export interface MenuDataType {
  path: string;
  name: string;
  icon?: React.ReactNode;
  children?: MenuDataType[];
}

export const menuData: MenuDataType[] = [
  {
    path: 'dm',
    name: '设备管理',
    icon: <RobotOutlined />,
    children: [
      { path: 'devices', name: '设备' },
      { path: 'histories', name: '历史数据' },
      { path: 'logs', name: '写入数据记录' },
    ],
  },
  {
    path: 'al',
    name: '报警管理',
    icon: <AlertOutlined />,
    children: [
      { path: 'alarms', name: '报警' },
      { path: 'alarm-logs', name: '报警通知' },
      { path: 'alarm-configs', name: '报警配置' },
      { path: 'alarm-categories', name: '报警类型' },
    ],
  },
  {
    path: 'wo',
    name: '工单管理',
    icon: <CarryOutOutlined />,
    children: [
      { path: 'workorder', name: '工单' },
      { path: 'workorder-categories', name: '工单类型' },
      { path: 'solutions', name: '解决方案' },
      { path: 'failure-categories', name: '故障类型' },
    ],
  },
  {
    path: 'vd',
    name: '云视频',
    icon: <VideoCameraOutlined />,
    children: [
      { path: 'video', name: '视频监控' },
      { path: 'gbvideo', name: 'GB28181配置' },
      { path: 'ysvideo', name: '萤石云配置' },
      { path: 'levideo', name: '乐橙云配置' },
    ],
  },
  {
    path: 'sys',
    name: '系统设置',
    icon: <UserOutlined />,
    children: [
      { path: 'in-companies', name: '内部公司' },
      { path: 'companies', name: '公司' },
      { path: 'roles', name: '角色' },
      { path: 'users', name: '用户' },
    ],
  },
];
