import { useAntdTable } from 'ahooks';
import { Badge, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  CreateNavButton,
  DeleteButton,
  DetailNavButton,
  EditNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { AlarmConfig } from '../alarm-config.interface';
import { deleteAlarmConfig, getAlarmConfigs } from '../alarm-config.service';
import { Link } from 'react-router-dom';
import { ProBreadcrumb } from 'pro';

const AlarmConfigList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getAlarmConfigs, {
    //refreshOnWindowFocus: true,
  });
  const columns: ColumnsType<AlarmConfig> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '配置名称',
      render: (_, { id, name }) => <Link to={id.toString()}>{name}</Link>,
    },

    {
      title: '报警类型',
      render: (_, { alarmCategory }) =>
        alarmCategory && (
          <Tag color="blue" key={alarmCategory.id}>
            {alarmCategory.name}
          </Tag>
        ),
    },
    {
      title: '启用配置',
      render: (_, { enabled }) =>
        enabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        ),
    },
    {
      title: '启用通知',
      render: (_, { noticeEnabled }) =>
        noticeEnabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        ),
    },
    {
      title: '通知人员',
      render: (_, { noticeTo }) =>
        noticeTo &&
        noticeTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
    {
      title: '自动派发',
      render: (_, { autoDispatchEnabled }) =>
        autoDispatchEnabled ? (
          <Badge status="success" text="启用" />
        ) : (
          <Badge status="default" text="停用" />
        ),
    },
    {
      title: '派发人员',
      render: (_, { maintenanceTo }) =>
        maintenanceTo &&
        maintenanceTo
          .map((u) => u.name)
          .filter((e) => e)
          .join(' '),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          <EditNavButton id={id} />
          <DeleteButton onDel={() => deleteAlarmConfig(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">报警配置</h2>
          <Space>
            <CreateNavButton />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default AlarmConfigList;
