import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CreateRoleDto } from 'auth/auth.interface';
import { createRole } from 'auth/auth.service';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import PermissionsCheckBox from './CheckBox';

const CreateRoleModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
}> = ({ open, setOpen, refresh }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createRole, { manual: true });
  const [checkListValue, setCheckListValue] = useState<CheckboxValueType[]>([]);

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: CreateRoleDto) => {
    try {
      setAlert({ display: false });
      value.permissions = checkListValue as string[];
      await runAsync(value);
      message.success('新增成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="新增用户"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        name="create-role-form"
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="角色名" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="permissions" label="权限" wrapperCol={{ span: 20 }}>
          <PermissionsCheckBox
            checkListValue={checkListValue}
            setCheckListValue={setCheckListValue}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRoleModal;
