import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import type { UploadFile } from 'antd/es/upload/interface';
import { User } from 'auth/auth.interface';
import { getColleagues } from 'auth/auth.service';
import Goback from 'common/Goback';
import { Device } from 'devices/device/devices.interface';
import { getDevicesSelected } from 'devices/device/devices.service';
import { UploadImagesInput } from 'files/UploadImagesInput';
import { convertFileList, normFile } from 'files/files.service';
import { ProBreadcrumb, ProSpin } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { dayjsDate } from 'pro/proModal/form.pro';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getWorkorderCategoriesSelected } from 'workorder/workorder-category/workorder-category.service';
import { UpdateWorkorderDto } from '../workorder.interface';
import { getWorkorder, updateWorkorderByLeader } from '../workorder.service';

const WorkorderUpdate: React.FC = () => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateWorkorderByLeader, {
    manual: true,
  });
  const { data: workorderCategories, loading: l2 } = useRequest(
    getWorkorderCategoriesSelected
  );
  const { data: devices, loading: l3 } = useRequest(getDevicesSelected);
  const { data: colleagues, loading: l4 } = useRequest(getColleagues);
  const navigate = useNavigate();

  const { workorderId } = useParams();
  if (!workorderId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(workorderId);
  const { loading: l1 } = useRequest(getWorkorder, {
    defaultParams: [id],
    onSuccess: (v) => {
      file1State[1](convertFileList(v.problemAttachments));
      form.setFieldsValue(v);
      form.setFieldsValue({
        startAt: dayjsDate(v.startAt),
        planEndAt: dayjsDate(v.planEndAt),
      });
      v.categories &&
        form.setFieldsValue({
          categoryIds: v.categories.map((r) => r.id),
        });
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
  };
  const onFinish = async (value: UpdateWorkorderDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      onCancel();
      form.resetFields();
      navigate(-1);
      message.success('编辑成功!');
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  const file1State = useState<UploadFile[]>();
  const [colleagueState, setColleagueState] = useState<User>();
  const onValuesChange = ({ device }: { device: Device }) => {
    if (devices && device?.id) {
      const dev = devices.find((d) => d.id === device.id);
      if (dev) {
        setColleagueState(dev.maintainer);
      }
    }
  };
  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">编辑工单</h2>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: '880px' }}>
        <Card>
          {l1 || l2 || l3 || l4 ? (
            <ProSpin />
          ) : (
            <Form
              layout="vertical"
              form={form}
              preserve={false}
              onFinish={onFinish}
              onValuesChange={onValuesChange}
            >
              <FormAlert {...alert} />
              <Form.Item
                name="categoryIds"
                label="工单类型"
                rules={[{ required: true }]}
              >
                <Select
                  mode="multiple"
                  placeholder="选择工单类型"
                  allowClear
                  options={
                    workorderCategories &&
                    workorderCategories.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item
                name={['device', 'id']}
                label="设备"
                rules={[{ required: true }]}
              >
                <Select
                  value={colleagueState}
                  placeholder="选择设备"
                  allowClear
                  options={
                    devices &&
                    devices.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item
                name="startAt"
                label="实施时间"
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="planEndAt"
                label="计划完成时间"
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name={['maintainer', 'id']}
                label="维护人员"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="选择维护人员"
                  allowClear
                  options={
                    colleagues &&
                    colleagues.map((m) => ({
                      value: m.id,
                      label: m.name,
                    }))
                  }
                />
              </Form.Item>
              <Form.Item
                name="problem"
                label="问题描述"
                rules={[{ required: true }]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item
                name="problemAttachments"
                label="附件"
                getValueFromEvent={normFile}
              >
                <UploadImagesInput fileState={file1State} />
              </Form.Item>
              <Row>
                <Col
                  className="gutter-row"
                  span={24}
                  style={{ textAlign: 'right' }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    保存
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default WorkorderUpdate;
