import { useRequest } from 'ahooks';
import { Modal } from 'antd';
import { useState } from 'react';
import JessibucaPlayer from 'react-jessibuca';
import { getGbvideo, startVideo, stopVideo } from '../gbvideo.service';
import './index.css';

const GbvideoModal: React.FC<{
  state: [
    {
      open: boolean;
      id: number;
    },
    React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        id: number;
      }>
    >
  ];
}> = ({ state: [{ open, id }, setOpen] }) => {
  const [videoState, setVideoState] = useState({
    deviceId: '',
    channelCode: '',
    src: '',
  });
  useRequest(getGbvideo, {
    defaultParams: [id],
    onSuccess: async ({ deviceId, channelCode }) => {
      try {
        const res = await startVideo({
          deviceId,
          channelId: channelCode,
        });
        setVideoState({ deviceId, channelCode, src: res.data.ws_flv });
      } catch (err) {}
    },
  });

  const onCancel = async () => {
    setOpen((v) => ({ ...v, open: false }));
    if (videoState.deviceId) {
      await stopVideo({
        deviceId: videoState.deviceId,
        channelId: videoState.channelCode,
      });
    }
  };

  return (
    <Modal
      title="播放视频"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
      className="video-modal"
    >
      <div id="gb-video-container-wrap">
        {videoState.src && (
          <JessibucaPlayer
            width={752}
            height={461}
            src={videoState.src}
            decoder="/decoder.js"
            controls={{
              fullscreen: true,
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default GbvideoModal;
