import { useRequest } from 'ahooks';
import {
  Card,
  Descriptions,
  Empty,
  Image,
  Tag,
  type DescriptionsProps,
  Badge,
} from 'antd';
import Goback from 'common/Goback';
import { ProBreadcrumb, ProSpin } from 'pro';
import { Link, useParams } from 'react-router-dom';
import { getWorkorder } from '../workorder.service';
import { LinkToDevice } from 'devices/device/components/LinkToDevice';
import { dayjsfmt1 } from 'common/dayjsfmt';

const WorkorderDetail: React.FC = () => {
  const { workorderId } = useParams();
  if (!workorderId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(workorderId);
  const { data, loading } = useRequest(getWorkorder, {
    defaultParams: [id],
    onSuccess: (v) => {},
  });
  const m1 =
    data?.problemAttachments &&
    data?.problemAttachments.map((att) => att.downloadUrl as string);
  const m2 =
    data?.noteAttachments &&
    data?.noteAttachments.map((att) => att.downloadUrl as string);
  const items1: DescriptionsProps['items'] =
    data &&
    (({
      no,
      type,
      device,
      status,
      categories,
      client,
      maintainer,
      note,
      startAt,
      planEndAt,
      serviceEndAt,
      problem,
      solutions,
    }) => [
      {
        key: '1',
        label: '编号',
        children: no,
      },
      {
        key: '2',
        label: '来源',
        children: type,
      },
      {
        key: '3',
        label: '状态',
        children:
          (status === '受理中' && <Badge status="warning" text="受理中" />) ||
          (status === '处理中' && (
            <Badge status="processing" text="处理中" />
          )) ||
          (status === '已完成' && <Badge status="success" text="已完成" />) ||
          (status === '已关闭' && <Badge status="default" text="已关闭" />),
      },
      {
        key: '4',
        label: '设备',
        children: <LinkToDevice {...device} />,
      },
      {
        key: '5',
        label: '类型',
        children:
          categories &&
          categories.map((cate) => (
            <Tag color="blue" key={cate.id}>
              {cate.name}
            </Tag>
          )),
      },
      {
        key: '6',
        label: '联系人',
        children: client.name + ' | ' + client.phone,
      },
      {
        key: '7',
        label: '维护人员',
        children: maintainer.name + ' | ' + maintainer.phone,
      },
      {
        key: '8',
        label: '开始时间',
        children: dayjsfmt1(startAt),
      },
      {
        key: '9',
        label: '计划时间',
        children: dayjsfmt1(planEndAt),
      },
      {
        key: '10',
        label: '问题',
        children: problem,
        span: 3,
      },
      {
        key: '11',
        label: '问题照片',
        children: m1 ? (
          <Image.PreviewGroup items={m1}>
            {m1.map((m) => (
              <Image width={100} key={m} src={m} />
            ))}
          </Image.PreviewGroup>
        ) : (
          '-'
        ),
        span: 3,
      },
      {
        key: '12',
        label: '处理说明',
        children: note,
      },
      {
        key: '13',
        label: '完成时间',
        children: dayjsfmt1(serviceEndAt),
        span: 2,
      },
      {
        key: '14',
        label: '处理照片',
        children: m2 ? (
          <Image.PreviewGroup items={m2}>
            {m2.map((m) => (
              <Image width={100} key={m} src={m} />
            ))}
          </Image.PreviewGroup>
        ) : (
          '-'
        ),
        span: 3,
      },
      {
        key: '15',
        label: '查看解决方案',
        children: solutions && solutions.length > 0 && (
          <ul style={{ paddingLeft: 16 }}>
            {solutions.map((s) => (
              <li key={s.id}>
                <Link to={`/wo/solutions/${s.id.toString()}`}>{s.name}</Link>
              </li>
            ))}
          </ul>
        ),
        span: 3,
      },
    ])(data);

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">工单详情</h2>
          </div>
        </div>
      </div>
      <Card style={{ maxWidth: '880px', marginTop: '24px' }}>
        {loading ? (
          <ProSpin />
        ) : data ? (
          <Descriptions title="工单" items={items1} />
        ) : (
          <Empty />
        )}
      </Card>
    </div>
  );
};

export default WorkorderDetail;
