import { useAntdTable } from 'ahooks';
import { Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Role } from 'auth/auth.interface';
import { deleteRole, getRoles } from 'auth/auth.service';
import {
  CreateButton,
  DeleteButton,
  extraProps,
  RefreshButton,
  UpdateButton,
} from 'pro/table.pro';
import { permissions as allPermissions } from 'config/permissions';
import { useState } from 'react';
import CreateRoleModal from './Create';
import './index.css';
import UpdateRoleModal from './Update';
import { ProBreadcrumb } from 'pro';

const RoleFc: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getRoles, {
    //refreshOnWindowFocus: true,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [roleId, setRoleId] = useState(0);

  const columns: ColumnsType<Role> = [
    {
      title: '角色名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '权限',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_, { permissions }) => (
        <>
          {permissions ? (
            permissions.map((p) => (
              <Tag key={p}>
                {allPermissions.find((per) => per.code === p)?.name}
              </Tag>
            ))
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton
            onUpdate={() => {
              setRoleId(id);
              setUpdateState(true);
            }}
          />
          <DeleteButton onDel={() => deleteRole(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">角色管理</h2>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {createState ? (
        <CreateRoleModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
        />
      ) : (
        <></>
      )}
      {updateState ? (
        <UpdateRoleModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={roleId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default RoleFc;
