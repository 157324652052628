import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Gbvideo,
  CreateGbvideoDto,
  UpdateGbvideoDto,
} from './gbvideo.interface';
import { apiPrefix as api } from 'common/url.help';

export const getAllGbvideo = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/yunvideo/gb?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Gbvideo>>(url));
  return { total: data.total, list: data.items };
};

export const getGbvideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.get<Gbvideo>(url));
};

export const deleteGbvideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.delete<void>(url));
};

export const createGbvideo = async (value: CreateGbvideoDto) => {
  const url = api(`/video/yunvideo/gb`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateGbvideo = async (id: number, value: UpdateGbvideoDto) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const uploadScreenshot = ({
  fileName,
  base64,
}: {
  fileName: string;
  base64: string;
}) => {
  fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const fd = new FormData();
      const file = new File([blob], fileName);
      fd.append('image', file);

      // Let's upload the file
      // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
      const API_URL = '/files/uploads/screenshot';
      fetch(API_URL, { method: 'POST', body: fd })
        .then((res) => res.json())
        .then((res) => console.log(res));
    });
};

export const startVideo = async ({
  deviceId,
  channelId,
}: {
  deviceId: string;
  channelId: string;
}) => {
  const url = api(`/video/start/${deviceId}/${channelId}`);
  return getData(axios.get(url));
};

export const stopVideo = async ({
  deviceId,
  channelId,
}: {
  deviceId: string;
  channelId: string;
}) => {
  const url = api(`/video/stop/${deviceId}/${channelId}`);
  return getData(axios.get(url));
};
