import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  Company,
  CreateCompanyDto,
  CreateOrgDto,
  Org,
  UpdateCompanyDto,
  UpdateOrgDto,
} from './company.interface';
import { CreateUserDto, User } from 'auth/auth.interface';

const apiInCompany = (p = '') => {
  return api('/in-companies', p);
};

export const getInCompanies = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = apiInCompany('?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Company>>(url));
  return { total: data.total, list: data.items };
};

export const getInCompany = async (id: number) => {
  return getData(axios.get<Company>(apiInCompany('/' + id)));
};

export const deleteInCompany = async (id: number) => {
  return getData(axios.delete<void>(apiInCompany('/' + id)));
};

export const createInCompany = async (value: CreateCompanyDto) => {
  return getData(
    axios.post(apiInCompany(), value, { handlerErrorManually: true })
  );
};

export const updateInCompany = async (id: number, value: UpdateCompanyDto) => {
  return getData(
    axios.patch(apiInCompany('/' + id), value, {
      handlerErrorManually: true,
    })
  );
};

export const getOrgs = async (companyId: number) => {
  const url = api(`/in-companies/${companyId}/orgs`);
  return getData(axios.get<Org>(url));
};

export const getOrg = async (companyId: number, id: number) => {
  const url = api(`/in-companies/${companyId}/orgs/${id}`);
  return getData(axios.get<Org>(url));
};

export const deleteOrg = async (companyId: number, id: number) => {
  const url = api(`/in-companies/${companyId}/orgs/${id}`);
  return getData(axios.delete<void>(url));
};

export const createOrg = async (companyId: number, value: CreateOrgDto) => {
  const url = api(`/in-companies/${companyId}/orgs`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateOrg = async (
  companyId: number,
  id: number,
  value: UpdateOrgDto
) => {
  const url = api(`/in-companies/${companyId}/orgs/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const getInCompaniesUsers1 = async ({
  companyId,
  current,
  pageSize,
}: RequestParms & {
  companyId: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = api(
    `/in-companies/${companyId}/users?` + qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<User>>(url));
  return { total: data.total, list: data.items };
};

export const getInCompaniesUsers2 = async ({
  companyId,
  orgId,
  current,
  pageSize,
}: RequestParms & {
  companyId: number;
  orgId: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = api(
    `/in-companies/${companyId}/orgs/${orgId}/users?` +
      qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<User>>(url));
  return { total: data.total, list: data.items };
};

export const createInCompaniesUser = async (
  companyId: number,
  value: CreateUserDto
) => {
  const url = api(`/in-companies/${companyId}/users`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};
