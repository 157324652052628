import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
import { UpdateOrgDto } from '../company.interface';
import { getOrg, updateOrg } from '../in-company.service';

const UpdateOrgModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<{ open: boolean; id: number }>>;
  refresh: () => void;
  id: number;
  companyId: number;
}> = ({ open, setOpen, refresh, id, companyId }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateOrg, { manual: true });
  useRequest(getOrg, {
    defaultParams: [companyId, id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen((s) => {
      return { ...s, open: false };
    });
  };
  const onFinish = async (value: UpdateOrgDto) => {
    try {
      setAlert({ display: false });

      await runAsync(companyId, id, value);
      message.success('修改成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateOrgModal;
