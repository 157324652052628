import axios, { getData } from 'common/axios.pro';
import qs from 'qs';
import {
  Ysvideo,
  CreateYsvideoDto,
  UpdateYsvideoDto,
} from './ysvideo.interface';
import { apiPrefix as api } from 'common/url.help';

export const getAllYsvideo = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/video/yunvideo/ys?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Ysvideo>>(url));
  return { total: data.total, list: data.items };
};

export const getYsvideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.get<Ysvideo>(url));
};

export const deleteYsvideo = async (id: number) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.delete<void>(url));
};

export const createYsvideo = async (value: CreateYsvideoDto) => {
  const url = api(`/video/yunvideo/ys`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateYsvideo = async (id: number, value: UpdateYsvideoDto) => {
  const url = api(`/video/yunvideo/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};

export const uploadScreenshot = ({
  fileName,
  base64,
}: {
  fileName: string;
  base64: string;
}) => {
  fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const fd = new FormData();
      const file = new File([blob], fileName);
      fd.append('image', file);

      // Let's upload the file
      // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
      const API_URL = '/files/uploads/screenshot';
      fetch(API_URL, { method: 'POST', body: fd })
        .then((res) => res.json())
        .then((res) => console.log(res));
    });
};
