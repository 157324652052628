import React from 'react';
import {
  login as authServiceLogin,
  login2 as authServiceLogin2,
  logout as authServiceLogout,
  getUser as authServiceGetUser,
} from './auth.service';
import jwtDecode from 'jwt-decode';
import { User, LoginParams, LoginResult, Login2Params } from './auth.interface';

/*
- 支持登录登出
- 在全局范围内提供权限校验、用户数据
- 自动管理token
- 请求鉴权集成在自定义 axios 截断器中
- 路由鉴权集成在 layout 页面中
*/

interface AuthContextType {
  isAuth: boolean;
  getUser: () => Promise<User>;
  login: (parms: LoginParams) => Promise<LoginResult>;
  login2: (parms: Login2Params) => Promise<LoginResult>;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuth, setIsAuth] = React.useState<boolean>(
    !!localStorage.getItem('token')
  );
  const [user, setUser1] = React.useState<User>();

  const login = async (parms: LoginParams) => {
    const result = await authServiceLogin(parms);
    localStorage.setItem('token', result.token);
    setIsAuth(true);
    setUser1(result.user);
    return result;
  };

  const login2 = async (parms: Login2Params) => {
    const result = await authServiceLogin2(parms);
    localStorage.setItem('token', result.token);
    setIsAuth(true);
    setUser1(result.user);
    return result;
  };

  const logout = async () => {
    await authServiceLogout();
    localStorage.removeItem('token');
    setIsAuth(false);
    return Promise.resolve();
  };

  const getUser = async () => {
    if (!user) {
      const jwt = localStorage.getItem('token');
      if (jwt) {
        const token = jwtDecode<{
          sub: number;
          username: string;
        }>(jwt);
        const u = await authServiceGetUser(token.sub);
        setUser1(u);
        return u;
      } else {
        throw new Error('token 不存在！');
      }
    } else {
      return user;
    }
  };

  const value = { isAuth, getUser, login, login2, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };

/**
 * 获取 httpheader Bearer + token
 * 不存在跳转到登录页面
 */
export const getAuthorization = () => {
  // 检查 token
  const token = localStorage.getItem('token');
  if (!token) {
    return '';
  }
  return `Bearer ${token}`;
};
